@if (profile$ | async; as profile) {
  <form [formGroup]="form" class="grid grid-cols-12">
    <div class="flex flex-col col-span-12 md:col-span-6 md:col-start-4">
      <h1 class="font-bold text-4xl">Privatsphäre</h1>
    </div>
    <div
      class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4 pt-8">
      <div class="flex flex-col">
        <div class="flex justify-between">
          <span class="font-bold text-base"> Analytics und Tracking </span>
          <mat-slide-toggle
            color="primary"
            formControlName="analyticTrackingEnabled"></mat-slide-toggle>
        </div>
        <span class="text-base text-[#B5B6C1] pr-24">
          Diese Cookies ermöglichen uns zu verstehen, wie du unsere Website
          nutzt, damit wir sie gezielt optimieren können.
        </span>
      </div>
      <div class="flex justify-end gap-8">
        <a mat-button color="primary" routerLink="/settings">Zurück</a>
        <app-action-button
          [disabled]="!form.valid"
          (onClick)="save()"
          text="Speichern"></app-action-button>
      </div>
    </div>
  </form>
}
