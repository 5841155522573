<div class="grid grid-cols-12">
  <app-info-banner
    [visible]="bannerVisible"
    [closable]="true"
    class="col-span-full"
    headline="Mach mit und hilf, das Profil deiner Schule zu stärken!"
    storageKey="profile-membership">
    Ab jetzt hast du die Möglichkeit, im Schulprofil deines aktuellen
    Arbeitgebers angezeigt zu werden. Alles, was du dafür tun musst, ist, deine
    Schule in deinem Profil hinzuzufügen.
    <a
      [routerLink]="['/me', profile.id, 'edit', 'school']"
      class="text-bn-primary-blue hover:text-bn-primary-blue-hover hover:underline">
      Jetzt hinzufügen
    </a>
  </app-info-banner>

  <div class="col-span-12 relative">
    <img
      alt="Profile Background"
      class="h-full w-full rounded-[0.75rem] object-center object-cover"
      [src]="getBackgroundUrl(profile)" />
    @if (isEditable) {
      <app-edit-button
        icon="photo_camera"
        [routerLink]="['/me', profile.id, 'edit', 'background']"
        class="absolute bottom-1 right-1 md:bottom-5 md:right-5" />
    }
  </div>
  <div class="flex flex-col col-span-12 relative">
    <div class="flex justify-center">
      <div class="absolute -top-[92px] md:-top-[136px] xl:-top-[236px]">
        <img
          alt="Profile Avatar"
          class="w-[120px] md:w-[180px] xl:w-[300px] h-auto rounded-full border-white border-2"
          [src]="getAvatarUrl(profile)" />
        @if (isEditable) {
          <app-edit-button
            icon="photo_camera"
            [routerLink]="['/me', profile.id, 'edit', 'avatar']"
            class="absolute -bottom-1 -right-1 md:right-4 xl:bottom-1.5 xl:right-11" />
        }
      </div>
    </div>
    <div class="flex flex-col items-start pt-9 md:gap-2 md:pt-16 xl:pt-[84px]">
      <div class="flex w-full gap-4 items-center justify-between">
        <strong class="block text-2xl truncate w-full line-clamp-2 md:text-4xl">
          {{ profile.name }}
        </strong>
        @if (isEditable) {
          <app-edit-button [routerLink]="['/me', profile.id, 'edit', 'job']" />
        }
      </div>
      <div class="flex flex-col relative w-full">
        <!-- Location -->
        @if (profile.city) {
          <div class="flex gap-2 items-start w-full mb-2 md:mb-3">
            <mat-icon class="material-symbols-rounded size-6 min-w-6">
              location_on
            </mat-icon>
            <span class="block text-sm truncate leading-6 md:text-base">
              {{ getLocation(profile) }}
            </span>
          </div>
        }
        <div class="flex gap-2 items-start w-full mb-3 md:mb-5">
          <mat-icon class="material-symbols-rounded size-6 min-w-6">
            work
          </mat-icon>
          <span class="block text-sm leading-6 md:text-base">
            {{ getJobTitles(profile.jobFunctions) }}
          </span>
        </div>
        @if (
          showContactButton() ||
          profile.interestedInFulltimeJobs ||
          profile.interestedInSubstituteJobs
        ) {
          <ul class="flex flex-col gap-2 md:flex-row md:items-center">
            @if (showContactButton()) {
              <li class="flex md:flex-none">
                <button
                  mat-flat-button
                  color="primary"
                  class="flex"
                  (click)="openChat()">
                  <mat-icon class="material-symbols-rounded">forum</mat-icon>
                  Kontaktieren
                </button>
              </li>
            }
            @if (profile.interestedInFulltimeJobs) {
              <li class="flex md:flex-none">
                <app-chip skin="job_fulltime" text="Offen für Feststellen" />
              </li>
            }
            @if (profile.interestedInSubstituteJobs) {
              <li class="flex md:flex-none">
                <app-chip
                  skin="job_substitute"
                  text="Offen für Stellvertretungen" />
              </li>
            }
            @if (profile.interestedInHospitation) {
              <li class="flex md:flex-none">
                <app-chip skin="hospitation" text="Offen für Hospitationen" />
              </li>
            }
          </ul>
        }
        <app-profile-header-memberships
          [pid]="profile.id"
          [schools]="memberships"
          [isEditable]="isEditable" />
      </div>
    </div>
  </div>
</div>
