import { GeocodeResult } from '@googlemaps/google-maps-services-js';
import { SchoolLevels } from './school';

export type NotificationSubscription<T = unknown> = {
  id?: string;
  createdOn?: string;
  userId: string;
  userName: string;
  targets: NotificationTarget[];
  type: 'job';
  data: T;
};

export type NotificationTarget = {
  type: 'email';
  value: string;
};

export type JobSubscriptionData = {
  radius: number;
  employmentType: 'fulltime' | 'substitute' | 'both';
  schoolLevels: (typeof SchoolLevels)[number][]; // Schulstufen
  location: GeocodeResult;
};

export enum MembershipsNotificationEnum {
  ADDED_BY_HIMSELF = 'AddedByHimself',
  REMOVED_BY_HIMSELF = 'RemovedByHimself',
  REMOVED_BY_ADMIN = 'RemovedByAdmin',
}

export type MembershipsNotificationType =
  | MembershipsNotificationEnum.ADDED_BY_HIMSELF
  | MembershipsNotificationEnum.REMOVED_BY_HIMSELF
  | MembershipsNotificationEnum.REMOVED_BY_ADMIN;

export type MembershipsNotificationData = {
  schoolIds: string[];
  userId: string;
  action: MembershipsNotificationType;
};
