<form class="grid grid-cols-12" [formGroup]="form">
  <div class="flex flex-col gap-12 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Berufsbezeichnung</h1>
      <p class="text-base text-[#B5B6C1]">
        Gib die Berufsbezeichnungen
        <span class="font-black">in der Reihenfolge</span>
        an, in welcher sie beim Profil-Header angezeigt werden sollen,
        angefangen beim obersten! Gib nur Jobs an, für die du qualifiziert bist
        oder für die du in Ausbildung bist.
      </p>
    </div>

    <div class="flex flex-col gap-6">
      <div class="flex flex-col">
        <div class="flex">
          <mat-form-field appearance="fill" class="grow">
            <mat-label>Berufsbezeichnung</mat-label>
            <input
              type="text"
              placeholder="Schreibe hier rein!"
              aria-label="Fächer"
              matInput
              [formControl]="form.controls.jobFunctions"
              [matAutocomplete]="auto"
              (keyup.enter)="add()" />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="onSelectJobFunction($event)">
              @for (
                jobFunction of filteredJobFunctions$ | async;
                track jobFunction
              ) {
                <mat-option [value]="jobFunction">
                  {{ jobFunction }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
          <div class="pl-4 pt-2.5">
            <button
              [disabled]="!form.controls.jobFunctions.value"
              mat-flat-button
              color="primary"
              (click)="add()">
              Hinzufügen
            </button>
          </div>
        </div>

        @for (
          selectedJobFunction of selectedJobFunctions;
          track selectedJobFunction;
          let i = $index
        ) {
          <div class="flex flex-col gap-4 pt-16">
            <div class="flex grow items-center justify-between">
              <span class="font-bold text-xl">{{
                selectedJobFunction.value
              }}</span>
              <button mat-icon-button (click)="remove(i)">
                <mat-icon class="material-symbols-rounded">delete</mat-icon>
              </button>
            </div>
            <div class="flex flex-col pb-4">
              <mat-form-field>
                <mat-label>
                  Stufen (falls für deine Berufsfunktion relevant)
                </mat-label>
                <mat-select
                  [formControl]="selectedJobFunction.jobLevelsControl"
                  multiple>
                  @for (level of schoolLevels; track $index) {
                    <mat-option [value]="level">
                      {{ level }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              @if (showJobLevelDescriptionsField(selectedJobFunction)) {
                <mat-form-field>
                  <mat-label>Stufenspezifikation</mat-label>
                  <mat-select
                    [formControl]="
                      selectedJobFunction.jobLevelDescriptionsControl
                    "
                    multiple>
                    @for (
                      description of schoolLevelDescriptions;
                      track $index
                    ) {
                      <mat-option [value]="description">
                        {{ description }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              <div class="flex flex-col text-base pl-[18px]">
                <div class="flex justify-between">
                  <span>In Ausbildung</span>
                  <mat-slide-toggle
                    color="primary"
                    [formControl]="
                      selectedJobFunction.inStudyControl
                    "></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <mat-divider />

      <div class="flex flex-col">
        <h1 class="font-bold text-2xl">
          Bist du auf Stellensuche oder offen für einen kollegialen Austausch im
          Rahmen einer Hospitation?
        </h1>
        <p class="text-base text-[#B5B6C1]">
          Deine Auswahl wird in deinem Profil angezeigt und kann gefiltert
          werden.
        </p>
      </div>

      <div class="flex flex-col">
        <mat-checkbox
          color="primary"
          [formControl]="form.controls.interestedInFulltimeJobs"
          >Offen für Feststellen</mat-checkbox
        >
        <mat-checkbox
          color="primary"
          [formControl]="form.controls.interestedInSubstituteJobs"
          >Offen für Stellvertretungen</mat-checkbox
        >
        <mat-checkbox
          color="primary"
          [formControl]="form.controls.interestedInHospitation"
          >Offen für Hospitationen</mat-checkbox
        >
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <button
        mat-flat-button
        color="primary"
        [disabled]="selectedJobFunctions.length < 1"
        (click)="save()">
        Speichern
      </button>
    </div>
  </div>
</form>
