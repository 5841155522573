import { Component, Input } from '@angular/core';
import { Profile } from '../../../../types/profile';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-profile-about-me-card',
  templateUrl: './profile-about-me-card.component.html',
  styleUrls: ['./profile-about-me-card.component.scss'],
})
export class ProfileAboutMeCardComponent {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isEditable!: boolean;

  get showEmptyCard() {
    return isRichTextFieldEmpty(this.profile.about_html);
  }
}
