import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EMPTY,
  Observable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { SchoolService } from '../../../../services/school.service';
import {
  School,
  SchoolAddress,
  CollegesOfEducation,
  CollegesOfEducationInfoMap,
} from '../../../../types/school';
import { LocationsService } from '../../../../services/locations.service';
import { GeoLocation } from '../../../../types/core';

@Component({
  selector: 'app-school-info-form',
  templateUrl: './school-info-form.component.html',
  styleUrls: ['./school-info-form.component.scss'],
})
export class SchoolInfoFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;

  collegesOfEducations = CollegesOfEducation;
  filteredCollegesOfEducations$: Observable<string[]> = EMPTY;

  location?: GeoLocation;

  form = this.fb.group({
    cooperatingCollegeOfEducation: ['', []],
    countStudents: ['', [Validators.required]],
    countEmployees: ['', [Validators.required]],
    street: ['', [Validators.required]],
    zipcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    website: ['', []],
  });

  locationFetchRequest$: Observable<{
    isLoading: boolean;
    error: boolean;
    location?: GeoLocation;
  }> = EMPTY;

  constructor(
    route: ActivatedRoute,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private locationsService: LocationsService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = this.schoolService.getById(this.id);

    this.form.controls.city.disable();
    this.locationFetchRequest$ = this.form.controls.zipcode.valueChanges
      .pipe(
        skip(1),
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        filter(zipcode => !!zipcode && zipcode.length > 3),
        switchMap(zipcode =>
          this.locationsService.searchAddress(`Schweiz ${zipcode}`).pipe(
            switchMap(result => {
              if (result.status === 'OK') {
                return this.locationsService.getLocation(zipcode!, result).pipe(
                  map(location => ({
                    isLoading: false,
                    location,
                    error: false,
                  })),
                  tap(location => {
                    this.location = location.location;
                    this.form.controls.city.setValue(
                      location.location?.city || ''
                    );
                  })
                );
              } else {
                return EMPTY;
              }
            }),
            startWith({
              isLoading: true,
              location: undefined,
              error: false,
            }),
            catchError(() => {
              this.location = undefined;
              this.form.controls.city.setValue('');
              this.form.controls.zipcode.setErrors({
                required: true,
              });
              return of({
                isLoading: false,
                location: undefined,
                error: true,
              });
            })
          )
        )
      )
      .pipe(
        startWith({
          isLoading: false,
          location: undefined,
          error: false,
        })
      );
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.form.setValue({
        cooperatingCollegeOfEducation:
          school.cooperatingCollegeOfEducation || '',
        countStudents: school.countStudents || '',
        countEmployees: school.countEmployees || '',
        street: school.address?.street || '',
        zipcode: school.address?.zipcode || '',
        city: school.address?.city || '',
        website: school.address?.website || '',
      } as any);

      this.location = { geoloc: school._geoloc };
    });

    this.filteredCollegesOfEducations$ =
      this.form.controls.cooperatingCollegeOfEducation.valueChanges.pipe(
        startWith(''),
        map(value => this.filterColleges(value || ''))
      );
  }

  async save() {
    const values = this.form.getRawValue();
    console.log({ values });
    const address: SchoolAddress = {
      street: values.street || '',
      zipcode: values.zipcode || '',
      city: values.city || '',
      canton: this.location?.canton?.replace('Kanton ', '') || '',
      cantonShort: this.location?.cantonShort || '',
      municipality: this.location?.municipality || '',
      website: values.website || '',
    };

    // Check if college value is valid
    let college = values.cooperatingCollegeOfEducation;
    college = CollegesOfEducation.includes(
      college as (typeof CollegesOfEducation)[number]
    )
      ? college
      : '';

    this.schoolService
      .update(this.id, {
        cooperatingCollegeOfEducation:
          college as (typeof CollegesOfEducation)[number],
        countEmployees: values.countEmployees || '',
        countStudents: values.countStudents || '',
        _geoloc: this.location?.geoloc,
        address,
      })
      .subscribe(async () => {
        await this.router.navigate(['schools', this.id]);
      });
  }

  getDisplayNameOfCollege(college: string) {
    const fullName =
      CollegesOfEducationInfoMap[
        college as keyof typeof CollegesOfEducationInfoMap
      ].name || '';

    return `${fullName} (${college})`;
  }

  private filterColleges(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.collegesOfEducations.filter(
      option =>
        option.toLowerCase().includes(filterValue) ||
        this.getDisplayNameOfCollege(option).toLowerCase().includes(filterValue)
    );
  }
}
