<section class="school-locations">
  @if (!swiper?.isBeginning) {
    <button
      class="school-locations__button school-locations__button--back"
      (click)="swiper?.slidePrev()">
      <mat-icon class="material-symbols-rounded">arrow_back_ios_new</mat-icon>
      <span class="sr-only">zurück</span>
    </button>
  }
  <div id="location-swiper" class="swiper school-locations__swiper" #swiperRef>
    <div class="swiper-wrapper school-locations__swiper__wrapper">
      @for (location of locations(); track location.id; let i = $index) {
        <article
          class="swiper-slide school-locations__swiper__wrapper__slide"
          [ngClass]="{
            'school-locations__swiper__wrapper__slide--pipe':
              i === 0 && !!locations().length
          }">
          <ng-container
            [ngTemplateOutlet]="item"
            [ngTemplateOutletContext]="{
              loc: location,
              first: i === 0,
              selected: location.id === schoolId
            }" />
          @if (i === 0 && !!locations().length) {
            <hr />
          }
        </article>
      }
    </div>
  </div>
  @if (!swiper?.isEnd) {
    <button
      class="school-locations__button school-locations__button--forward"
      (click)="swiper?.slideNext()">
      <mat-icon class="material-symbols-rounded">arrow_forward_ios</mat-icon>
      <span class="sr-only">vorwärts</span>
    </button>
  }
</section>

<ng-template #item let-location="loc" let-first="first" let-selected="selected">
  <a [routerLink]="['/schools', location.id]" class="cursor-pointer">
    <mat-chip
      class="location school-locations__swiper__wrapper__slide__chip"
      [ngClass]="{ selected, first }">
      {{ location.name }}
    </mat-chip>
  </a>
</ng-template>
