import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { JobContact } from '../../../../../../types/job';
import { encode } from 'html-entities';
import { from } from 'rxjs';
import { StreamChannelService } from '../../../../../../../modules/chat/services/stream-channel.service';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile } from '../../../../../../types/profile';
import { getPublicFileUrl } from 'src/app/core/helpers';

@Component({
  selector: 'app-job-contact-card-item',
  templateUrl: './job-contact-card-item.component.html',
  styleUrl: './job-contact-card-item.component.scss',
})
export class JobContactCardItemComponent implements OnInit, AfterViewInit {
  readonly contact = input.required<JobContact>();
  readonly email = computed(() => encode(this.contact().email));
  readonly isPreview = input.required<boolean>();

  me: Profile | null = null;
  protected readonly getPublicFileUrl = getPublicFileUrl;

  readonly router = inject(Router);
  readonly renderer = inject(Renderer2);
  readonly streamChannelService = inject(StreamChannelService);
  readonly profileService = inject(ProfileService);

  @ViewChild('link')
  link!: ElementRef;

  ngOnInit(): void {
    this.profileService.getMe().subscribe(me => (this.me = me));
  }

  ngAfterViewInit(): void {
    this.renderer.setAttribute(
      this.link.nativeElement,
      'href',
      `mailto:${this.contact().email}`
    );
  }

  async openChat(): Promise<void> {
    if (this.contact().userId) {
      from(this.streamChannelService.create([this.contact().userId])).subscribe(
        async ({ id }) => {
          await this.router.navigate(['/chat/overview', id]);
        }
      );
    }
  }
}
