import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent {
  @Input() background = true;
  @Input() icon = 'custom_edit';
  @Input() color: 'dark' | 'light' = 'dark';
  @Input() disabled: boolean = false;
}
