@if (!showEmptyCard) {
  <div class="flex flex-col gap-4">
    <div class="flex w-fit">
      <strong class="font-bold text-2xl md:text-4xl"> Das bieten wir </strong>
      @if (isEditable) {
        <app-edit-button class="pl-4" routerLink="edit/weoffer" />
      }
    </div>
    <app-chip-list
      class="pt-4"
      [items]="job.whatWeOfferHighlights"
      [chipSkin]="this.job.isFullTimeJob ? 'job_fulltime' : 'job_substitute'" />
    <app-rich-text-view
      [html]="job.weOffer_html"
      tailwindCssLineClamp="line-clamp-[10]" />
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Das bieten wir"
    subtitle="Mut zum Perspektivenwechsel."
    description="Überlege was sich Bewerbende von ihrem zukünftigen Arbeitgeber wünschen. Wie sieht ein richtig attraktives Angebot aus Bewerbersicht aus und was davon kannst du bieten?"
    link="edit/weoffer" />
}
