@if (currentProfile$ | async; as currentProfile) {
  <div class="flex flex-col gap-14 md:gap-16 2xl:gap-20">
    <div class="grid grid-cols-12">
      @if (currentProfile.id) {
        <app-membership-banner-card
          [pid]="currentProfile.id"
          class="col-span-full" />
      }
      @if (statistics$ | async; as statistics) {
        <div class="grid grid-col-1 md:grid-cols-2 col-span-full gap-4">
          @if (schools$ | async; as schools) {
            <div class="col-span-full flex gap-6">
              <app-welcome-school-card class="grow" [schools]="schools" />
            </div>
          }
        </div>
      }
    </div>
    <div class="flex flex-col gap-6">
      <div class="grid grid-cols-12">
        <div class="flex justify-between col-span-full gap-4 items-start">
          <h1 class="font-bold text-2xl md:text-4xl m-0">Neue Stellen</h1>
          <a
            class="self-center"
            routerLink="/jobs"
            mat-flat-button
            color="primary">
            Suchen
          </a>
        </div>
      </div>
      <div
        class="grid grid-cols-12 gap-4 [&>*:nth-child(3)]:block md:[&>*:nth-child(3)]:hidden lg:[&>*:nth-child(3)]:block">
        @for (job of jobs$ | async; track job.id) {
          <app-job-card
            class="col-span-12 md:col-span-6 lg:col-span-4 cursor-pointer"
            [job]="job"
            [id]="job.id!"
            [routerLink]="['/jobs', job.id]" />
        }
      </div>
    </div>
    <div class="flex flex-col gap-6">
      <div class="grid grid-cols-12">
        <div class="flex justify-between col-span-full gap-4 items-start">
          <h1 class="font-bold text-2xl md:text-4xl m-0">Neue Fachkräfte</h1>
          <a routerLink="/profile" mat-flat-button color="primary">Suchen</a>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-12 gap-4">
          <div
            class="grid grid-cols-12 gap-4 col-span-full [&>*:nth-child(3)]:block md:[&>*:nth-child(3)]:hidden lg:[&>*:nth-child(3)]:block">
            @for (profile of profiles$ | async; track profile.id) {
              <app-profile-card
                class="col-span-12 md:col-span-6 lg:col-span-4 cursor-pointer"
                [profile]="profile"
                [id]="profile.id!"
                [routerLink]="[
                  currentProfile.id === profile.id ? '/me' : '/profile',
                  profile.id
                ]" />
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
