@if (!showEmptyCard) {
  <div class="flex flex-col">
    <div class="flex justify-between">
      <strong class="font-bold text-2xl md:text-4xl">
        Pädagogischer Fokus
      </strong>
      @if (isEditable) {
        <app-edit-button
          class="pl-4"
          routerLink="edit/pedagogic-focus"></app-edit-button>
      }
    </div>
    <app-chip-list
      class="pt-4"
      [items]="school.pedagogicFocusHighlights"
      [chipSkin]="chipSkin" />
    <app-rich-text-view
      class="pt-4"
      [html]="school.pedagogicFocus_html"
      tailwindCssLineClamp="line-clamp-[17]"></app-rich-text-view>
  </div>
}

@if (showEmptyCard && isEditable) {
  <app-empty-card
    title="Pädagogischer Fokus"
    subtitle="Das Kernstück einer Bildungsinstitution"
    description="Pädagogik hat ganz viel mit Haltung und Wissen zu tun. Sie ist matchentscheidend dafür, ob eine nachhaltige Passung mit künftigen Mitarbeitenden vorhanden ist. Jede Schule setzt hier eigene Schwerpunkte."
    link="edit/pedagogic-focus"></app-empty-card>
}
