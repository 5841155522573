import { Component, inject, input, OnInit, output } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
} from 'ngx-image-compress';

export type MediaFormCropperData = {
  file: File;
  isCover?: boolean;
};

@Component({
  selector: 'app-media-form-cropper',
  templateUrl: './media-form-cropper.component.html',
  styleUrl: './media-form-cropper.component.scss',
})
export class MediaFormCropperComponent implements OnInit {
  readonly data = input.required<MediaFormCropperData>();
  readonly fillColor = input<string>();

  cropped = output<File | null>();

  loading: boolean = false;
  transform: ImageTransform = { scale: 1, translateUnit: 'px' };
  base64Image?: DataUrl = undefined;
  croppedImage: File | null = null;
  readonly factor: number = 3;
  readonly dimension: { width: number; height: number } = {
    width: 1120,
    height: 630,
  };

  readonly imageCompress = inject(NgxImageCompressService);

  ngOnInit(): void {
    this.selectFile(this.data().file);
  }

  private selectFile(file: File): void {
    this.loading = true;

    if (file) {
      const reader = new FileReader();
      reader.onloadend = e => this.compressFile(e.target?.result as DataUrl);
      reader.readAsDataURL(file);
    }
  }

  private compressFile(url: DataUrl): void {
    this.imageCompress
      .compressFile(
        url,
        DOC_ORIENTATION.Default,
        50,
        100,
        Math.ceil(this.dimension.width * this.factor),
        Math.ceil(this.dimension.height * this.factor)
      )
      .then(result => (this.base64Image = result));
  }

  zoomOut() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! - 0.1,
    };
  }

  zoomIn() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! + 0.1,
    };
  }

  imageCropped(event: ImageCroppedEvent): void {
    if (event.blob) {
      this.croppedImage = new File([event.blob], this.data().file.name, {
        lastModified: new Date().getTime(),
      });
    }
  }

  save(): void {
    this.cropped.emit(this.croppedImage);
  }
}
