import { Component, inject, Input, ViewChild } from '@angular/core';
import { BitrateOptions, VgApiService } from '@videogular/ngx-videogular/core';
import { VgHlsDirective } from '@videogular/ngx-videogular/streaming';
import type { ApiVideoAsset } from '../../types/core';
import { ApiVideoService } from '../../services/api-video.service';
import { deviceState } from '../../signals/state';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  @ViewChild(VgHlsDirective, { static: false }) vgHls:
    | VgHlsDirective
    | undefined;

  @Input() src?: string;
  @Input() hls?: string;
  @Input({ required: true }) type!: string;
  @Input() cover?: string | null = null;
  @Input() encodings?: ApiVideoAsset['encodings'] = [];

  hlsBitrates: BitrateOptions[] = [];
  isPlaying = false;
  playedOnce = false;
  isHovering = false;
  lastHoveringTimeout?: NodeJS.Timeout;

  readonly apiVideoService = inject(ApiVideoService);

  showOverlay() {
    return (
      (this.playedOnce && !this.isPlaying) ||
      (this.playedOnce && this.isHovering)
    );
  }

  setBitrate(option: BitrateOptions) {
    if (this.vgHls) {
      this.vgHls.setBitrate(option);
    }
  }

  onMouseEnter() {
    this.isHovering = true;
    if (this.lastHoveringTimeout) clearTimeout(this.lastHoveringTimeout);
    this.lastHoveringTimeout = setTimeout(() => {
      this.isHovering = false;
    }, 2500);
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  onPlayerReady(api: VgApiService) {
    api.getDefaultMedia().subscriptions.playing.subscribe(() => {
      this.playedOnce = true;
      this.isPlaying = true;
    });

    api.getDefaultMedia().subscriptions.pause.subscribe(() => {
      this.isPlaying = false;
    });
  }

  getBitrate(rates: BitrateOptions[]) {
    if (!this.encodings?.length) {
      this.hlsBitrates = rates.map(rate => ({
        ...rate,
        label: this.apiVideoService.bitrates.find(
          ({ qualityIndex }) => qualityIndex === rate.qualityIndex
        )?.label,
      }));
    } else {
      this.hlsBitrates = this.apiVideoService.bitrates.slice(
        0,
        this.encodings.filter(({ encoding }) => encoding === 'hls').length
      );
    }

    if (!deviceState.isMobile()) {
      this.setBitrate(this.hlsBitrates[this.hlsBitrates.length - 1]);
    }
  }
}
