import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  interval,
  map,
  Observable,
  skip,
  startWith,
  switchMap,
} from 'rxjs';
import {
  School,
  SchoolLevels,
  SchoolLevelDescriptions,
  SchoolTypes,
} from '../../types/school';
import { Hit } from '@algolia/client-search';
import { LocationsService } from '../../services/locations.service';
import { GeocodeResult, PlaceType2 } from '@googlemaps/google-maps-services-js';
import { SchoolService } from '../../services/school.service';
import { PublicToolbarService } from '../../services/public-toolbar.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OrderProcessComponent } from 'src/app/dialogs/order-process/order-process.component';
import { ProfileService } from 'src/app/services/profile.service';
import type { SubscriptionType } from '../../types/subscription';

const subscriptionMapping: { [key: string]: SubscriptionType } = {
  s: 'SCHOOL_S',
  l: 'SCHOOL_L',
  custom: 'SCHOOL_CUSTOM',
};

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
})
export class SchoolsComponent implements OnInit, AfterViewInit {
  placeholderIndex = 0;
  placeholderOptions = [
    'Suche',
    'Pädagogische Kriterien',
    'Orte',
    'Stufen',
    'Sonstiges',
  ];

  page = 0;
  maxPage = 0;
  readonly defaultHitsPerPage = 32;
  readonly possibleRadius = [10, 20, 30, 50, 100];
  showFilters = false;

  schools: Hit<School>[] = [];
  locations$: Observable<GeocodeResult[]> = EMPTY;
  selectedLocation?: GeocodeResult;
  place?: unknown;
  isAuthorized: boolean = false;

  controls = {
    search: new FormControl<string>(''),
    location: new FormControl<string>(''),
    radius: new FormControl<number | undefined>(undefined),
    types: new FormControl<(typeof SchoolTypes)[number][]>([]),
    levels: new FormControl<(typeof SchoolLevels)[number][]>([]),
    levelDescriptions: new FormControl<
      (typeof SchoolLevelDescriptions)[number][]
    >([]),
  };

  schoolTypes = SchoolTypes;
  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;

  constructor(
    private locationsService: LocationsService,
    private schoolService: SchoolService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toolbarService: PublicToolbarService,
    private dialog: MatDialog,
    private profileService: ProfileService
  ) {
    this.toolbarService.set({ showBoth: true });

    this.activatedRoute.params
      .pipe(combineLatestWith(this.profileService.isAuthorized()))
      .subscribe(([params, isAuthorized]) => {
        this.isAuthorized = isAuthorized;
        if (subscriptionMapping[params['subscription']] && isAuthorized) {
          this.openOrderDialog({
            page: 1,
            type: subscriptionMapping[params['subscription']],
          });
        }
      });
  }

  async fetch(page: number) {
    this.page = page;
    const result = await this.schoolService.search(
      this.controls.search.value || '',
      {
        facetFilters: this.getFilters(),
        aroundRadius: this.getRadius(),
        aroundLatLng: this.getLatLng(),
        page: this.page,
        hitsPerPage: this.defaultHitsPerPage,
      }
    );
    this.maxPage = result.nbPages - 1;
    if (this.page === 0) {
      this.schools = result.hits;
    } else {
      this.schools = [...this.schools, ...result.hits];
    }
  }

  async ngOnInit() {
    interval(3000).subscribe(() => {
      if (this.placeholderOptions.length - 1 === this.placeholderIndex) {
        this.placeholderIndex = 0;
      } else {
        this.placeholderIndex++;
      }
    });

    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    this.setInputFieldsFromQueryParams(queryParamMap);
    this.registerListeners();
    await this.loadInitalData(queryParamMap);

    this.activatedRoute.queryParamMap
      .pipe(skip(1), debounceTime(300))
      .subscribe(async _ => {
        await this.fetch(this.page);
      });
  }

  private setInputFieldsFromQueryParams(queryParamMap: ParamMap) {
    if (queryParamMap.get('location')) {
      this.selectedLocation = JSON.parse(
        queryParamMap.get('location')!
      ) as GeocodeResult;
      this.controls.location.setValue(queryParamMap.get('location'));
    }

    if (queryParamMap.get('query')) {
      this.controls.search.setValue(queryParamMap.get('query'));
    }

    if (queryParamMap.get('radius')) {
      this.controls.radius.setValue(parseInt(queryParamMap.get('radius')!));
    }

    if (queryParamMap.get('levels')) {
      this.controls.levels.setValue(JSON.parse(queryParamMap.get('levels')!));
    }

    if (queryParamMap.get('levelDescriptions')) {
      this.controls.levelDescriptions.setValue(
        JSON.parse(queryParamMap.get('levelDescriptions')!)
      );
    }
  }

  private registerListeners() {
    this.locations$ = this.controls.location.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      filter(query => !!query && query.length > 2),
      switchMap(value => this.locationsService.searchAddress(value!)),
      filter(r => r.status === 'OK'),
      map(result => result.results)
    );

    this.controls.location.valueChanges
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        skip(1),
        filter(query => !query || query.length === 0)
      )
      .subscribe(async _ => {
        this.selectedLocation = undefined;
        await this.removeFilter(this.controls.location, '', 'location');
        await this.reload();
      });

    this.controls.search.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('query', value!);
        await this.reload();
      });

    this.controls.radius.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('radius', JSON.stringify(value));
        await this.reload();
      });

    this.controls.types.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('types', JSON.stringify(value));
        await this.reload();
      });

    this.controls.levels.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams('levels', JSON.stringify(value));
        await this.reload();
      });

    this.controls.levelDescriptions.valueChanges
      .pipe(startWith(''), skip(1), distinctUntilChanged())
      .subscribe(async value => {
        await this.updateQueryParams(
          'levelDescriptions',
          JSON.stringify(value)
        );
        await this.reload();
      });
  }

  private async loadInitalData(queryParamMap: ParamMap) {
    const s = queryParamMap.get('s') || '0';
    const selectionIndex = parseInt(s);
    const countPages = Math.ceil(selectionIndex / this.defaultHitsPerPage) + 1;
    for (let page = 0; page < countPages; page++) {
      await this.fetch(page);
    }
  }

  ngAfterViewInit() {
    // scroll to selection
    setTimeout(() => {
      const h = parseInt(
        this.activatedRoute.snapshot.queryParamMap.get('h') as string
      );
      if (h) {
        document!.querySelector('#scroll-area')!.scrollTop = h;
      }
    }, 300);
  }

  async reload() {
    this.page = 0;
    await this.updateQueryParams('s');
  }

  async setLocation(geocode: GeocodeResult) {
    this.selectedLocation = geocode;
    await this.updateQueryParams('location', JSON.stringify(geocode));
    await this.reload();
  }

  displayLocationFn(result: GeocodeResult): string {
    return result && result.formatted_address ? result.formatted_address : '';
  }

  getRegionalFilters(props: {
    radius: number | undefined | null;
    location: GeocodeResult | undefined;
  }) {
    const filters: string[][] = [[]];
    if ((!props.radius || props.radius == 0) && props.location) {
      const canton = props.location.address_components.find(x =>
        x.types.includes(PlaceType2.administrative_area_level_1)
      )?.long_name;

      const city =
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.locality)
        )?.long_name ||
        props.location.address_components.find(x =>
          x.types.includes(PlaceType2.administrative_area_level_2)
        )?.long_name;

      const zipcode = props.location.address_components.find(x =>
        x.types.includes(PlaceType2.postal_code)
      )?.long_name;

      if (zipcode) {
        filters[0].push(`address.zipcode:${zipcode}`);
      }
      if (city) {
        filters[0].push(`address.city:${city}`);
      }
      if (city && !zipcode) {
        filters[0].push(`address.municipality:${city}`);
      }

      if (canton && !city && !zipcode) {
        filters[0].push(`address.canton:${canton}`);
        filters[0].push(`address.canton:Kanton ${canton}`);
        filters[0].push(`address.canton:${canton?.replace('Kanton ', '')}`);
      }
    }

    return filters;
  }

  getFilters() {
    let levels = this.controls.levels.value || [];
    levels = this.schoolService.getZyklusLevelsFilter(levels);

    const types = this.controls.types.value || [];
    const levelDescriptions = this.controls.levelDescriptions.value || [];

    const radius = this.controls.radius.value;
    const regionalFilters = this.getRegionalFilters({
      radius,
      location: this.selectedLocation,
    });

    const filters = [
      ['isPublic:true'],
      ...regionalFilters,
      types.map(t => `type:${t}`),
      levels.map(t => `levels:${t}`),
      levelDescriptions.map(t => `levelDescriptions:${t}`),
    ];

    return filters;
  }

  getRadius() {
    return this.controls.radius.value || undefined;
  }

  getLatLng() {
    if (this.selectedLocation?.geometry?.location) {
      const { lat, lng } = this.selectedLocation.geometry.location;
      return `${lat}, ${lng}`;
    }

    return undefined;
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  async removeFilter(control: FormControl, value: unknown, key: string) {
    if (Array.isArray(control.value)) {
      const i = control.value.indexOf(value);
      if (i > -1) control.value.splice(i, 1);
    } else {
      control.setValue('');
    }

    if (control === this.controls.location) {
      this.selectedLocation = undefined;
    }

    await this.updateQueryParams(key);
    await this.reload();
  }

  async fetchNext() {
    await this.fetch(this.page + 1);
  }

  private async updateQueryParams(
    key: string,
    value?: string | number | boolean
  ) {
    const queryParams = {} as Record<string, unknown>;
    queryParams[key] = value;

    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams,
    });
  }

  async navigate(event: Event, school: Hit<School>, index: number) {
    event.preventDefault();
    const h = document.getElementById('scroll-area')?.scrollTop;
    await this.updateQueryParams('h', h);
    await this.updateQueryParams('s', index);
    await this.router.navigate(['/schools', school.objectID], {
      queryParamsHandling: 'merge',
    });
  }

  openOrderDialog(data?: { page?: number; type: SubscriptionType }) {
    this.dialog.open(OrderProcessComponent, {
      minWidth: '100vw',
      minHeight: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      data,
    });
  }
}
