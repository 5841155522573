<div class="school-sticky-bar">
  <div class="school-sticky-bar__container">
    <div class="school-sticky-bar__container__inner">
      <ng-content />
      @if (locations.length > 1) {
        <app-school-header-locations [locations]="locations" />
      }
    </div>
  </div>
</div>
