<div
  class="flex flex-col border-[1.5px] rounded-2xl h-full w-full p-5"
  [ngClass]="{
    'bg-job-fulltime-light border-job-fulltime-light': job.isFullTimeJob,
    'bg-job-substitute-light border-job-substitute-light': !job.isFullTimeJob,
    'hover:border-job-fulltime-dark': hasHover && job.isFullTimeJob,
    'hover:border-job-substitute-dark': hasHover && !job.isFullTimeJob
  }">
  <div class="flex justify-between">
    <div class="rounded-xl">
      <div
        class="h-20 w-20 rounded-xl bg-center bg-no-repeat bg-contain"
        [ngStyle]="{
          'background-image':
            'url(' +
            getAvatarUrl() +
            '), url(/assets/defaults/school_avatar.svg)'
        }"></div>
    </div>
    <div class="flex flex-col items-end justify-between">
      <div class="flex gap-1 justify-end items-center w-full">
        <app-job-badge [type]="job.isFullTimeJob ? 'fulltime' : 'substitute'" />
        @if (menuItems.length > 0) {
          @if (!isProcessing) {
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()">
              <mat-icon class="material-symbols-rounded font-bold"
                >more_vert</mat-icon
              >
            </button>
            <mat-menu #menu>
              @for (menuItem of menuItems; track menuItem.id) {
                <button
                  mat-menu-item
                  (click)="
                    $event.stopPropagation(); clickContextMenuItem(menuItem.id)
                  ">
                  <mat-icon>{{ menuItem.icon }}</mat-icon>
                  <span>{{ menuItem.text }}</span>
                </button>
              }
            </mat-menu>
          } @else {
            <mat-spinner class="m-[5px]" diameter="30"></mat-spinner>
          }
        }
      </div>
      @if (job.isPublic && !job.isClosed) {
        <app-passed-time-info [time]="job.modifiedOn" type="small" />
      }
    </div>
  </div>
  <div class="flex flex-col mt-5 gap-5">
    <div>
      <strong
        class="text-2xl line-clamp-2 text-ellipsis"
        [ngClass]="
          job.isFullTimeJob
            ? 'text-job-fulltime-dark'
            : 'text-job-substitute-dark'
        ">
        {{ job.function }}</strong
      >
      <span class="text-base bright-font line-clamp-1 text-ellipsis">
        {{ getTitle(job) }}
      </span>
    </div>
    <div class="text-base bright-font">
      <span class="font-bold line-clamp-1 text-ellipsis">{{
        getJobType()
      }}</span>
      <span class="line-clamp-1 text-ellipsis">
        {{ getWorkloadAndDurationText(job) }}
      </span>
    </div>
    <div class="text-base bright-font">
      <span class="font-bold line-clamp-1 text-ellipsis">
        {{ job.schoolName }}
      </span>
      <span class="line-clamp-1 text-ellipsis">
        {{ getJobLocation() }}
      </span>
    </div>
  </div>
  <ng-content></ng-content>
</div>
