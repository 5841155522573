import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from '../../../../services/job.service';
import { Job } from '../../../../types/job';
import { convertRichTextToPlainText } from 'src/app/core/richText';
import { ChipSkin } from 'src/app/components/chip/chip.component';

@Component({
  selector: 'app-job-we-offer-form',
  templateUrl: './job-we-offer-form.component.html',
  styleUrls: ['./job-we-offer-form.component.scss'],
})
export class JobWeOfferFormComponent {
  id: string;
  job$: Observable<Job>;
  loading = false;

  form = this.fb.group({
    weOffer: ['', []],
    whatWeOfferHighlights: [],
  });

  highlightSuggestions = [
    'Gratis Kaffee',
    'Guter Teamspirit',
    'Sport über Mittag',
    'Obst für Mitarbeitende',
    'Gratis Parkplätze',
    'Grosszügige Weiterbildungsbeiträge',
    'Sitzungspflicht nur an Arbeitstagen',
    'Moderne Infrastruktur',
    'Digital top ausgestattet',
    'Unterstützendes Umfeld',
    'Grosse Klassenzimmer',
    'Gruppenräume',
    'Unterstützendes Team',
    'Unterstützende Schulleitung',
    'Pädagogische Freiräume',
    'Gute Anschliessung an ÖV',
    'Zentral',
  ];
  chipSkin: ChipSkin = 'job_fulltime';

  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.job$ = jobService.getById(this.id);

    this.job$.subscribe(job => {
      this.form.setValue({
        weOffer: job.weOffer_html || '',
        whatWeOfferHighlights: job.whatWeOfferHighlights || [],
      } as any);

      this.chipSkin = job.isFullTimeJob ? 'job_fulltime' : 'job_substitute';
    });
  }

  save() {
    this.loading = true;

    const { weOffer } = this.form.getRawValue();
    const payload = {
      weOffer: convertRichTextToPlainText(weOffer) || '',
      weOffer_html: weOffer || '',
      whatWeOfferHighlights:
        this.form.get('whatWeOfferHighlights')?.value || [],
    };

    this.jobService.update(this.id, payload).subscribe(async () => {
      this.loading = false;
      await this.router.navigate(['jobs', this.id]);
    });
  }
}
