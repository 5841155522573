import { Component, EventEmitter, Output } from '@angular/core';
import { SubscriptionType } from 'src/app/types/subscription';

@Component({
  selector: 'app-order-process-products',
  templateUrl: './order-process-products.component.html',
  styleUrls: ['./order-process-products.component.scss'],
})
export class OrderProcessProductsComponent {
  @Output() selectOffer = new EventEmitter<SubscriptionType>();

  offers: {
    type: SubscriptionType;
    title: string;
    priceTotal?: number;
  }[] = [
    {
      type: 'SCHOOL_S',
      title: 'Unter 200 Lernende',
      priceTotal: 990,
    },
    {
      type: 'SCHOOL_L',
      title: 'Über 200 Lernende',
      priceTotal: 1290,
    },
    {
      type: 'SCHOOL_CUSTOM',
      title: 'Kantone und Gemeinden',
    },
  ];

  getBenefits() {
    const benefits: {
      title: string;
      description: string;
      priceText: string | null;
    }[] = [
      {
        title: 'Netzwerk',
        description:
          'Schule im Fokus der Bildigsnetz-Mitglieder, aktives Personalrecruiting, praktische Such- und Kontaktfunktionen, Einzel- und Gruppenchats, multimediale Dateien teilen',
        priceText: 'Inklusive',
      },
      {
        title: 'Schulprofil',
        description:
          'Sämtliche Standorte der Schuleinheit, pädagogische Schwerpunkte, Video, Fotos, Audios, Texte',
        priceText: 'Inklusive',
      },
      {
        title: 'Stellenportal',
        description:
          'Unbegrenzte Anzahl Inserate - mit Videos, Fotos, Audios & mehr, automatisch mit dem Schulprofil verknüpft. Viele erleichternde Funktionen für effizienten Prozess, Einbindung in Homepage auf Anfrage möglich. Gezielte Bewerbung auf Social Media.',
        priceText: 'Inklusive',
      },
      {
        title: 'Bewerbungsmanagement',
        description:
          'Übersichtliches System zur Verwaltung aller Bewerbungen, alle Unterlagen und Kontaktdaten an einem Ort, flexible Zugriffsrechte innerhalb der Organisation, datenschutzkonform, voller praktischer Tools für einen effizienten Bewerbungsprozess!',
        priceText: 'Inklusive',
      },
      {
        title: 'Zugänge',
        description:
          'Einfach Administrationsrechte vergeben, unbeschränkte Anzahl Administratoren pro Schuleinheit.',
        priceText: 'Inklusive',
      },
    ];

    return benefits;
  }

  select(type: SubscriptionType) {
    this.selectOffer.emit(type);
  }
}
