@if (!isSchoolAdmin) {
  <app-info-banner
    [visible]="isVisible"
    [closable]="true"
    headline="Mach mit und hilf, das Profil deiner Schule zu stärken!"
    storageKey="home-user-membership">
    Ab jetzt hast du die Möglichkeit, im Schulprofil deiner Schule angezeigt zu
    werden! Füge deine Schule einfach in deinem persönlichen Profil hinzu.
    <a
      [routerLink]="['/me', pid(), 'edit', 'school']"
      class="text-bn-primary-blue hover:text-bn-primary-blue-hover hover:underline">
      Jetzt hinzufügen
    </a>
  </app-info-banner>
} @else {
  <app-info-banner
    [visible]="isVisible"
    [closable]="true"
    headline="Zeige dein Team in deinem Schulprofil!"
    storageKey="home-admin-membership">
    Ab jetzt haben deine Mitarbeitenden die Möglichkeit, in deinem Schulprofil
    angezeigt zu werden! Hierfür müssen sie deine Schule einfach in ihrem
    persönlichen Profil hinzufügen.
  </app-info-banner>
}
