<vg-player
  class="flex rounded-xl md:rounded-2xl justify-center"
  (onPlayerReady)="onPlayerReady($event)"
  (mousemove)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <vg-overlay-play></vg-overlay-play>
  <vg-buffering></vg-buffering>
  <vg-scrub-bar [style.visibility]="showOverlay() ? 'visible' : 'hidden'">
    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
  </vg-scrub-bar>
  <vg-controls [style.visibility]="showOverlay() ? 'visible' : 'hidden'">
    <vg-play-pause></vg-play-pause>
    <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
    <vg-scrub-bar style="pointer-events: none"></vg-scrub-bar>
    <vg-mute></vg-mute>
    <vg-volume></vg-volume>
    @if (!!hls) {
      <vg-quality-selector
        [bitrates]="hlsBitrates"
        (onBitrateChange)="setBitrate($event)"></vg-quality-selector>
    }
    <vg-fullscreen></vg-fullscreen>
  </vg-controls>
  @if (!!src) {
    <video
      [vgMedia]="$any(media)"
      #media
      id="singleVideo"
      preload="auto"
      crossorigin
      [poster]="cover">
      <source [src]="src" [type]="type" />
    </video>
  }
  @if (!!hls) {
    <video
      #media
      #vgHls="vgHls"
      type="video/mp4"
      [vgMedia]="$any(media)"
      [vgHls]="hls"
      id="singleVideo"
      preload="auto"
      crossorigin
      [poster]="cover"
      (onGetBitrates)="getBitrate($event)"></video>
  }
</vg-player>
