import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../../../../services/profile.service';
import { Profile } from '../../../../types/profile';

@Component({
  selector: 'app-profile-preferences-form',
  templateUrl: './profile-preferences-form.component.html',
  styleUrls: ['./profile-preferences-form.component.scss'],
})
export class ProfilePreferencesFormComponent {
  id: string;
  profile$: Observable<Profile>;

  suggestions: string[] = [
    'Teamfähigkeit',
    'Flexibilität',
    'Optimismus',
    'Belastbarkeit',
    'Organisationskompetenz',
    'Kreativität',
    'Analystisches Denken',
    'Innovationsfreude',
  ];
  form = this.fb.group({
    preferences: [],
  });

  constructor(
    private profileService: ProfileService,
    private router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.profile$ = profileService.getMe(false);

    this.profile$.subscribe(profile => {
      this.form.setValue({
        preferences: profile.preferences || [],
      } as any);
    });
  }

  save() {
    const preferences = this.form.get('preferences')?.value || [];
    this.profileService.update({ preferences }).subscribe(_ => {
      this.router.navigate(['me', this.id]);
    });
  }
}
