<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Das bieten wir</h1>

    <app-rich-text-field
      placeholderText="Habt ihr tolle Räume, einen coolen Pausenplatz, eine gute Stimmung im Team oder eine super Kaffeemaschine? Verfolgt ihr eine gemeinsame pädagogische Vision und seid Stolz auf eure Errungenschaften? Solche Dinge und viele mehr, könntest du hier aufführen."
      formControlName="weOffer"></app-rich-text-field>

    <app-chip-list-field
      [formControl]="form.controls.whatWeOfferHighlights"
      [suggestions]="highlightSuggestions"
      [chipSkin]="chipSkin" />

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
