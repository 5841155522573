import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { School } from '../../../../types/school';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'app-school-progress-card',
  templateUrl: './school-progress-card.component.html',
  styleUrls: ['./school-progress-card.component.scss'],
})
export class SchoolProgressCardComponent implements OnChanges {
  @Input({ required: true }) school!: School;

  // required
  hasType = false;
  hasInfo = false;
  hasAbout = false;
  hasContacts = false;

  // optional
  hasVideo = false;
  hasMedia = false;
  hasWeOffer = false;
  hasPedagogicFocus = false;
  hasDocuments = false;

  constructor(private schoolService: SchoolService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['school']) {
      this.checkProgress();
    }
  }

  get permissionLink() {
    return `/schools/${this.school.rootId}/edit/permissions`;
  }

  checkProgress() {
    // required
    const requiredCheck = this.schoolService.checkRequiredFields(this.school);
    this.hasType = requiredCheck.hasType;
    this.hasInfo = requiredCheck.hasInfo;
    this.hasAbout = requiredCheck.hasAbout;
    this.hasContacts = requiredCheck.hasContacts;

    // optional
    this.hasVideo = !!this.school.video;
    this.hasMedia = !!this.school.media && this.school.media.length > 0;
    this.hasWeOffer =
      !!this.school.whatWeOffer ||
      (!!this.school.whatWeOfferHighlights &&
        this.school.whatWeOfferHighlights.length > 0);

    this.hasPedagogicFocus =
      !!this.school.pedagogicFocus ||
      (!!this.school.pedagogicFocusHighlights &&
        this.school.pedagogicFocusHighlights.length > 0);

    this.hasDocuments = !!this.school.documents;
  }
}
