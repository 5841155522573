import { Component, Inject, inject } from '@angular/core';
import { Profile } from '../../../../../../types/profile';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { getPublicFileUrl } from '../../../../../../core/helpers';

export type SchoolMembershipsCardDialogProps = {
  profile: Profile;
};

@Component({
  selector: 'app-school-memberships-card-dialog',
  standalone: true,
  templateUrl: './school-memberships-card-dialog.component.html',
  styleUrl: './school-memberships-card-dialog.component.scss',
  imports: [MatDialogClose],
})
export class SchoolMembershipsCardDialogComponent {
  readonly dialogRef = inject(MatDialogRef<SchoolMembershipsCardDialogProps>);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SchoolMembershipsCardDialogProps
  ) {}

  getAvatarUrl(profile: Profile) {
    if (profile.avatar && profile.id) {
      return getPublicFileUrl('profiles', profile.id, 'avatar');
    }

    return 'assets/defaults/avatar.svg';
  }
}
