export type BaseModel = {
  id?: string;
  createdOn?: string;
  modifiedOn?: string;
};

export type BucketFile = {
  path: string;
  url?: string | null;
  mimetype?: string;
  filename?: string;
  description?: string;
  title?: string;
  type?: string;
  coverImage?: { path: string; url?: string | null };
};

export type ApiVideoAsset = {
  id: string;
  hls: string;
  mp4: string;
  poster?: string | null;
  encodings?: ApiVideoEncoding[];
};

export type ApiVideoEncoding = { encoding: 'hls' | 'mp4'; quality: string };

export type Avatar = {
  profile?: BucketFile;
  profileSmall?: BucketFile;
  background?: BucketFile;
  backgroundSmall?: BucketFile;
};

export type GeoLocation = {
  zipcode?: string;
  city?: string;
  canton?: string;
  cantonShort?: string;
  municipality?: string; // Gemeinde
  geoloc?: GeoPoint;
};

export type GeoPoint = {
  lat: number;
  lng: number;
};

export type MediaFile = {
  file?: Blob;
  displayUrl: string;
  description?: string;
  type: string;
  filename: string;
  uploaded?: boolean;
};

export const Languages = [
  'Afrikaans',
  'Albanisch',
  'Altgriechisch',
  'Amharisch',
  'Arabisch',
  'Armenisch',
  'Aserbaidschanisch',
  'Assamesisch',
  'Aymara',
  'Bambara',
  'Baskisch',
  'Belarussisch',
  'Bengalisch',
  'Bhojpuri',
  'Birmanisch',
  'Bosnisch',
  'Bulgarisch',
  'Cebuano',
  'Chichewa',
  'Chinesisch (traditionell)',
  'Chinesisch (vereinfacht)',
  'Dänisch',
  'check',
  'Deutsch',
  'Dhivehi',
  'Dogri',
  'Englisch',
  'Esperanto',
  'Estnisch',
  'Ewe',
  'Filipino',
  'Finnisch',
  'Französisch',
  'Friesisch',
  'Galizisch',
  'Gebärdensprache',
  'Georgisch',
  'Griechisch',
  'Guarani',
  'Gujarati',
  'Haitianisch',
  'Hausa',
  'Hawaiisch',
  'Hebräisch',
  'Hindi',
  'Hmong',
  'Igbo',
  'Ilokano',
  'Indonesisch',
  'Irisch',
  'Isländisch',
  'Italienisch',
  'Japanisch',
  'Javanisch',
  'Jiddisch',
  'Kannada',
  'Kasachisch',
  'Katalanisch',
  'Khmer',
  'Kinyarwanda',
  'Kirgisisch',
  'Konkani',
  'Koreanisch',
  'Korsisch',
  'Krio',
  'Kroatisch',
  'Kurdisch (Kurmandschi)',
  'Kurdisch (Sorani)',
  'Lao',
  'Latein',
  'Lettisch',
  'Lingala',
  'Litauisch',
  'Luganda',
  'Luxemburgisch',
  'Maithili',
  'Malagasy',
  'Malayalam',
  'Malaysisch',
  'Maltesisch',
  'Maori',
  'Marathi',
  'Mazedonisch',
  'Meitei (Manipuri)',
  'Mizo',
  'Mongolisch',
  'Nepalesisch',
  'Niederländisch',
  'Norwegisch',
  'Odia (Oriya)',
  'Oromo',
  'Paschtu',
  'Persisch',
  'Polnisch',
  'Portugiesisch',
  'Punjabi',
  'Quechua',
  'Rumänisch',
  'Russisch',
  'Samoanisch',
  'Sanskrit',
  'Schottisch-Gälisch',
  'Schwedisch',
  'Sepedi',
  'Serbisch',
  'Sesotho',
  'Shona',
  'Sindhi',
  'Singhalesisch',
  'Slowakisch',
  'Slowenisch',
  'Somali',
  'Spanisch',
  'Sundanesisch',
  'Swahili',
  'Tadschikisch',
  'Tamil',
  'Tatarisch',
  'Telugu',
  'Thailändisch',
  'Tigrinya',
  'Tschechisch',
  'Tsonga',
  'Türkisch',
  'Turkmenisch',
  'Twi',
  'Uigurisch',
  'Ukrainisch',
  'Ungarisch',
  'Urdu',
  'Usbekisch',
  'Vietnamesisch',
  'Walisisch',
  'Xhosa',
  'Yoruba',
  'Zulu',
] as const;
