import { Component, effect, input } from '@angular/core';
import { School, SchoolLocation } from '../../../../types/school';

@Component({
  selector: 'app-school-sticky-bar',
  templateUrl: './school-sticky-bar.component.html',
  styleUrl: './school-sticky-bar.component.scss',
})
export class SchoolStickyBarComponent {
  readonly school = input.required<School>();
  readonly isEditable = input.required<boolean>();

  locations: SchoolLocation[] = [];

  constructor() {
    effect(() => {
      this.collectLocations(this.school());
    });
  }

  private collectLocations(school?: School): void {
    if (school?.locations && school.locations?.length > 0) {
      this.locations = [
        {
          id: school.id,
          name: school.name,
          isRoot: school.isRoot,
          isPublic: school.isPublic,
          order: school.order,
        },
        ...school.locations,
      ];

      if (!this.isEditable()) {
        this.locations = this.locations.filter(l => !!l.isPublic);
      }

      this.locations = this.locations.sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }
        if (a.isRoot && !b.isRoot) {
          return -1;
        }
        if (!a.isRoot && b.isRoot) {
          return 1;
        }
        return b.name!.localeCompare(a.name!);
      });
    }
  }
}
