import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolService } from '../../../../services/school.service';
import { School } from '../../../../types/school';
import { convertRichTextToPlainText } from 'src/app/core/richText';
import { ChipSkin } from 'src/app/components/chip/chip.component';
import { getChipSkinFromSchoolType } from 'src/app/core/helpers';

@Component({
  selector: 'app-school-focus-form',
  templateUrl: './school-focus-form.component.html',
  styleUrls: ['./school-focus-form.component.scss'],
})
export class SchoolFocusFormComponent {
  id: string;
  school$: Observable<School>;

  form = this.fb.group({
    pedagogicFocus: ['', []],
    pedagogicFocusHighlights: [],
  });

  highlightSuggestions = [
    'AdL',
    'Churer-Modell',
    'Lernlandschaften',
    'Keine Noten',
    'LIFT-Projekt',
    'Traditionsbewusst',
    'Starke Lernbeziehungen',
    'Keine Hausaufgaben',
    'Individuelle Trainingszeit',
    'Schülerpartizipation',
    'Eigenständiges Lernen',
    'Projektorientierter Unterricht',
    'Niveaudurchmischt',
  ];
  chipSkin: ChipSkin = 'school_public';

  constructor(
    route: ActivatedRoute,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);

    this.school$.subscribe(school => {
      this.form.setValue({
        pedagogicFocus: school.pedagogicFocus_html || '',
        pedagogicFocusHighlights: school.pedagogicFocusHighlights || [],
      } as any);

      this.chipSkin = getChipSkinFromSchoolType(school.type);
    });
  }

  save() {
    const { pedagogicFocus } = this.form.getRawValue();
    const payload = {
      pedagogicFocus: convertRichTextToPlainText(pedagogicFocus) || '',
      pedagogicFocus_html: pedagogicFocus || '',
      pedagogicFocusHighlights:
        this.form.get('pedagogicFocusHighlights')?.value || [],
    };

    this.schoolService.update(this.id, payload).subscribe(async () => {
      await this.router.navigate(['schools', this.id]);
    });
  }
}
