import {
  AfterContentInit,
  Component,
  ElementRef,
  inject,
  input,
  OnInit,
  viewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swiper from 'swiper';

import { SchoolLocation } from '../../../../types/school';

@Component({
  selector: 'app-school-header-locations',
  templateUrl: './school-header-locations.component.html',
  styleUrl: './school-header-locations.component.scss',
})
export class SchoolHeaderLocationsComponent
  implements OnInit, AfterContentInit
{
  readonly locations = input.required<SchoolLocation[]>();
  readonly route = inject(ActivatedRoute);

  schoolId: SchoolLocation['id'];
  activeIndex: number = 0;
  swiper?: Swiper;
  swiperRef = viewChild<ElementRef>('swiperRef');

  constructor() {
    this.schoolId = this.route.snapshot.paramMap.get('id') ?? undefined;
  }

  ngOnInit(): void {
    this.activeIndex = this.locations().findIndex(
      loc => loc.id === this.schoolId
    );
  }

  ngAfterContentInit(): void {
    new Swiper('#location-swiper', {
      freeMode: true,
      slidesPerView: 'auto',
      centeredSlides: !!this.activeIndex,
      centeredSlidesBounds: !!this.activeIndex,
      breakpoints: {
        0: {
          spaceBetween: 8,
        },
        768: {
          spaceBetween: 16,
        },
      },
    });
    this.swiper = this.swiperRef()?.nativeElement.swiper;
    if (this.activeIndex) this.swiper?.slideTo(this.activeIndex);
  }
}
