import { Component, Input, OnInit } from '@angular/core';
import { getChipSkinColors } from 'src/app/components/chip/chip.component';

@Component({
  selector: 'app-job-type-chip',
  templateUrl: './job-type-chip.component.html',
  styleUrl: './job-type-chip.component.scss',
})
export class JobTypeChipComponent implements OnInit {
  @Input({ required: true }) type!: 'fulltime' | 'substitute';

  colors: { bg: string; text: string } = { bg: '', text: '' };

  ngOnInit(): void {
    this.colors = getChipSkinColors(
      this.type === 'fulltime' ? 'job_fulltime' : 'job_substitute'
    );
  }
}
