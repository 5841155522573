@if (profile$ | async; as profile) {
  <div class="flex justify-center">
    <div class="w-full max-w-[1120px]">
      <div class="flex flex-col gap-10 md:gap-20">
        <div class="flex flex-col gap-2">
          <app-profile-top-bar
            [profile]="profile"
            [isOwner]="isOwner"
            [isPreview]="isPreview" />

          <app-profile-header [isEditable]="isEditable" [profile]="profile" />
        </div>

        <div class="grid grid-cols-12">
          <div class="col-span-full flex flex-col gap-10 md:gap-20">
            @if (isEditable) {
              <app-profile-progress-card
                class="conditional-hide"
                [profile]="profile" />
            }

            <app-profile-preferences-card
              class="conditional-hide"
              [profile]="profile"
              [isEditable]="isEditable" />

            <app-profile-about-me-card
              class="conditional-hide"
              [profile]="profile"
              [isEditable]="isEditable" />

            <app-profile-qualification-card
              id="qualifications"
              class="conditional-hide"
              [profile]="profile"
              [isEditable]="isEditable" />

            <app-profile-work-experiencs-card
              class="conditional-hide"
              [profile]="profile"
              [isEditable]="isEditable" />

            <app-profile-education-card
              class="conditional-hide"
              [profile]="profile"
              [isEditable]="isEditable" />

            <app-profile-language-card
              class="conditional-hide"
              [profile]="profile"
              [isEditable]="isEditable" />
          </div>
        </div>
      </div>
    </div>
  </div>
}
