import { Injectable } from '@angular/core';
import { BucketFile } from '../types/core';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  Storage,
  getBlob,
} from '@angular/fire/storage';
import { v4 as uuid } from 'uuid';
import { saveAs } from 'file-saver';
import { FirebaseError } from '@angular/fire/app';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private storage: Storage) {}

  async blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  dataURLtoBlob(dataurl: string): Blob {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  async remove(key: string) {
    const storageRef = ref(this.storage, key);

    try {
      await deleteObject(storageRef);
    } catch (error) {
      // Handle case where file does not exist
      if (
        error instanceof FirebaseError &&
        error.code === 'storage/object-not-found'
      ) {
        // Do nothing
      } else {
        throw error;
      }
    }
  }

  async download(key: string, filename?: string) {
    const storageRef = ref(this.storage, key);
    const blob = await getBlob(storageRef);
    saveAs(blob, filename);
  }

  async uploadAsync(
    path: string,
    file: Blob,
    filename?: string,
    isPublic?: boolean,
    description?: string
  ): Promise<BucketFile> {
    const name = filename ? filename : `${uuid()}`;
    const key = `${path}/${name}`;
    const storageRef = ref(this.storage, key);

    // upload file
    await uploadBytes(storageRef, file);
    const publicUrl = isPublic ? await getDownloadURL(storageRef) : undefined;

    return {
      path: key,
      url: publicUrl || null,
      mimetype: file.type || '',
      filename: name,
      description: description || '',
    };
  }
}
