import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Profile, ProfileJobFunction } from '../../../types/profile';
import { JobFunctions } from '../../../types/job';
import { SchoolLevelDescriptions, SchoolLevels } from '../../../types/school';
import { LocationsService } from '../../../services/locations.service';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { BucketFile, GeoLocation } from '../../../types/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {
  getDownloadURL,
  ref,
  uploadBytes,
  Storage,
} from '@angular/fire/storage';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { StreamUserService } from '../../../../modules/chat/services/stream-user.service';
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
} from 'ngx-image-compress';

type SelectedJobFunction = {
  value: string;
  jobLevelsControl: FormControl<
    null | string[] | (typeof SchoolLevels)[number][]
  >;
  jobLevelDescriptionsControl: FormControl<
    null | string[] | (typeof SchoolLevelDescriptions)[number][]
  >;
  inStudyControl: FormControl<boolean | null | undefined>;
};
type PreviewMedia = { file?: Blob; displayUrl?: string };

@Component({
  selector: 'app-profile-wizard',
  templateUrl: './profile-wizard.component.html',
  styleUrls: ['./profile-wizard.component.scss'],
})
export class ProfileWizardComponent {
  page = 1;
  loading = false;
  profile?: Profile;
  location?: GeoLocation;

  schoolLevels = SchoolLevels;
  schoolLevelDescriptions = SchoolLevelDescriptions;

  media: PreviewMedia = {};
  deleted = false;
  croppedImage?: Blob | undefined | null;
  transform: ImageTransform = { scale: 1, translateUnit: 'px' };
  dimension: { width: number; height: number } = { width: 600, height: 600 };
  loadingImage = false;
  base64Image?: DataUrl = undefined;
  private factor: number = 3;
  readonly acceptTypes = ['.jpg', '.jpeg', '.png'];

  nameForm = this.fb.group({
    name: ['', [Validators.required]],
  });

  contactForm = this.fb.group({
    zipcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    phone: ['', []],
  });

  jobFunctions = JobFunctions.filter(
    jf => jf !== 'Klassenlehrperson' && jf !== 'Fachlehrperson'
  );
  iconColor = 'secondary';
  selectedJobFunctions: SelectedJobFunction[] = [];
  selectedJobFunctionsControl = new FormControl<SelectedJobFunction[]>(
    [],
    [Validators.required, Validators.minLength(1)]
  );
  jobFunctionControl = new FormControl<string | (typeof JobFunctions)[number]>(
    ''
  );
  filteredJobFunctions$: Observable<(typeof JobFunctions)[number][]> = EMPTY;
  reloadFilteredJobFunctions$ = new ReplaySubject(1);

  interestedInFulltimeJobsControl = new FormControl<boolean>(false);
  interestedInSubstituteJobsControl = new FormControl<boolean>(false);
  interestedInHospitationControl = new FormControl<boolean>(false);

  locationFetchRequest$: Observable<{
    isLoading: boolean;
    error: boolean;
    location?: GeoLocation;
  }> = EMPTY;

  constructor(
    private router: Router,
    private locationsService: LocationsService,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private storage: Storage,
    private streamUserService: StreamUserService,
    private imageCompress: NgxImageCompressService
  ) {
    this.filteredJobFunctions$ = this.reloadFilteredJobFunctions$.pipe(
      switchMap(() =>
        from(
          this.jobFunctionControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            map(value =>
              value && value.length > 0 ? this.filter(value || '') : []
            )
          )
        )
      )
    );

    this.jobFunctionControl.valueChanges.subscribe(value => {
      this.iconColor = value && value.length > 2 ? 'primary' : 'secondary';
    });

    this.reloadFilteredJobFunctions$.next(1);

    this.contactForm.controls.city.disable();
    this.locationFetchRequest$ = this.contactForm.controls.zipcode.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        filter(zipcode => !!zipcode && zipcode.length > 3),
        switchMap(zipcode =>
          this.locationsService.searchAddress(`Schweiz ${zipcode}`).pipe(
            switchMap(result => {
              if (result.status === 'OK') {
                return this.locationsService.getLocation(zipcode!, result).pipe(
                  map(location => ({
                    isLoading: false,
                    location,
                    error: false,
                  })),
                  tap(location => {
                    this.location = location.location;
                    this.contactForm.controls.city.setValue(
                      location.location?.city || ''
                    );
                  })
                );
              } else {
                return EMPTY;
              }
            }),
            startWith({
              isLoading: true,
              location: undefined,
              error: false,
            }),
            catchError(() => {
              this.location = undefined;
              this.contactForm.controls.city.setValue('');
              this.contactForm.controls.zipcode.setErrors({
                required: true,
              });
              return of({
                isLoading: false,
                location: undefined,
                error: true,
              });
            })
          )
        )
      )
      .pipe(
        startWith({
          isLoading: false,
          location: undefined,
          error: false,
        })
      );

    profileService.getMe(true).subscribe(p => {
      if (p.isComplete) {
        router.navigate(['profile', p.id]);
      }
      this.profile = p;
    });
  }

  back() {
    if (this.page > 1) {
      this.page--;
    }
  }

  submitContact() {
    if (this.page < 2) {
      this.page++;
    }
  }

  async upload(file: Blob): Promise<BucketFile> {
    const filename = `avatar`;
    const path = `/profiles/${this.profile?.id}/${filename}`;
    const storageRef = ref(this.storage, path);

    // upload file
    await uploadBytes(storageRef, file);
    const publicUrl = await getDownloadURL(storageRef);

    return {
      path,
      url: publicUrl,
      mimetype: file.type || '',
    };
  }

  async save() {
    this.loading = true;
    const { phone, ...contact } = this.contactForm.value;
    const { name } = this.nameForm.value!;

    const jobFunctions: ProfileJobFunction[] = this.selectedJobFunctions.map(
      j => ({
        name: j.value,
        jobLevels: (j.jobLevelsControl.value ||
          []) as (typeof SchoolLevels)[number][],
        jobLevelDescriptions: (j.jobLevelDescriptionsControl.value ||
          []) as (typeof SchoolLevelDescriptions)[number][],
        inStudy: !!j.inStudyControl.value,
      })
    );

    const interestedInFulltimeJobs =
      this.interestedInFulltimeJobsControl.value || false;

    const interestedInSubstituteJobs =
      this.interestedInSubstituteJobsControl.value || false;

    const interestedInHospitation =
      this.interestedInHospitationControl.value || false;

    const profile = {
      ...contact,
      jobFunctions,
      name,
      _geoloc: this.location?.geoloc || null,
      canton: this.location?.canton?.replace('Kanton ', '') || '',
      city: this.location?.city,
      cantonShort: this.location?.cantonShort || '',
      municipality: this.location?.municipality || '',
      isPublic: true,
      isComplete: true,
      interestedInFulltimeJobs,
      interestedInSubstituteJobs,
      interestedInHospitation,
    } as Partial<Profile>;

    let obs: Observable<void> = EMPTY;

    let avatar = null;
    if (this.croppedImage) {
      avatar = await this.upload(this.croppedImage!);
    }
    obs = this.profileService.update({ avatar });

    obs.subscribe(_ => {
      this.profileService.getMe(true).subscribe(() => {
        this.profileService
          .update(profile)
          .pipe(
            switchMap(() =>
              this.profileService.updateContact({ phone: phone! })
            ),
            switchMap(() =>
              this.streamUserService.createUser({
                name,
                username: name,
                image: avatar?.url,
                language: 'de',
              })
            )
          )
          .subscribe(async () => {
            await this.router.navigate(['me', this.profile?.id]);
            window.location.reload();
          });
      });
    });
  }

  selectFile(event: Event): void {
    this.loadingImage = true;
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = e => this.compressFile(e.target?.result as DataUrl);
      reader.readAsDataURL(file);
    }
  }

  compressFile(url: DataUrl): void {
    this.imageCompress
      .compressFile(
        url,
        DOC_ORIENTATION.Default,
        50,
        100,
        Math.ceil(this.dimension.width * this.factor),
        Math.ceil(this.dimension.height * this.factor)
      )
      .then(result => (this.base64Image = result));
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  clear() {
    this.deleted = true;
    this.media = {};
  }

  getImageUrl() {
    return 'assets/defaults/avatar.svg';
  }

  zoomOut() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! - 0.1,
    };
  }

  zoomIn() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! + 0.1,
    };
  }

  remove(index: number) {
    this.selectedJobFunctions.splice(index, 1);
    this.selectedJobFunctionsControl.setValue(this.selectedJobFunctions);
    this.reloadFilteredJobFunctions$.next(1);
  }

  selectJobFunction(jobFunction: string) {
    this.jobFunctionControl?.setValue('');
    if (this.selectedJobFunctions.some(j => j.value === jobFunction)) return;
    this.selectedJobFunctions.push({
      value: jobFunction,
      inStudyControl: new FormControl(false),
      jobLevelsControl: new FormControl([]),
      jobLevelDescriptionsControl: new FormControl([]),
    });

    this.reloadFilteredJobFunctions$.next(1);
    this.selectedJobFunctionsControl.setValue(this.selectedJobFunctions);
  }

  onSelectJobFunction(event: MatAutocompleteSelectedEvent) {
    const jobFunction = event.option.value as string;
    this.selectJobFunction(jobFunction);
  }

  add() {
    const value = this.jobFunctionControl.value as string;
    if (value && value.length > 2) {
      this.selectJobFunction(value);
    }
  }

  showJobLevelDescriptionsField(selectedJobFunction: SelectedJobFunction) {
    const jobLevels = selectedJobFunction.jobLevelsControl.value;
    return !!jobLevels && jobLevels.some(l => l === 'Sekundarstufe II');
  }

  private filter(value: string) {
    const filterValue = value.toLowerCase();
    const selectedJobFunctions = this.selectedJobFunctions?.map(j => j.value);

    return this.jobFunctions
      .filter(jobFunction => !selectedJobFunctions.includes(jobFunction))
      .filter(jobFunction => jobFunction.toLowerCase().includes(filterValue));
  }
}
