<section class="profile-header-memberships" id="memberships">
  @if (isEditable() && !hasMemberships()) {
    <div class="profile-header-memberships__add">
      <button
        class="profile-header-memberships__add__button"
        [routerLink]="['/me', pid(), 'edit', 'school']">
        <span class="profile-header-memberships__add__button__inner">
          Schule hinzufügen
        </span>
      </button>
    </div>
  } @else {
    <div class="profile-header-memberships__exists">
      <ul class="profile-header-memberships__exists__list">
        @for (school of schools(); track school.id) {
          <li class="profile-header-memberships__exists__list__item">
            <a
              class="profile-header-memberships__exists__list__item__link"
              [routerLink]="['/schools', school.id]">
              <figure
                class="profile-header-memberships__exists__list__item__link__avatar">
                <img [alt]="school.name" [src]="getSchoolAvatarUrl(school)" />
              </figure>
              <span
                class="profile-header-memberships__exists__list__item__link__text">
                {{ school.name }}
              </span>
            </a>
          </li>
        }
      </ul>
      @if (isEditable()) {
        <app-edit-button [routerLink]="['/me', pid(), 'edit', 'school']" />
      }
    </div>
  }
</section>
