<div
  class="w-fit rounded-lg px-3 py-1.5 text-sm font-medium slight-shadow flex items-center gap-2"
  [ngClass]="[colors.bg, colors.text]">
  <span class="-mb-[3px]">{{ displayText }}</span>
  @if (removable) {
    <mat-icon
      class="material-symbols-rounded scale-[0.8] -mr-2 cursor-pointer"
      (click)="removed.emit()"
      >close</mat-icon
    >
  }
</div>
