import { Component } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { Profile } from '../../../types/profile';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileDetailComponent {
  id?: string;
  profile$?: Observable<Profile>;
  fetch$ = new ReplaySubject(1);

  isOwner = false;
  isPreview = false;

  constructor(profileService: ProfileService, route: ActivatedRoute) {
    route.paramMap.subscribe(params => {
      this.id = params.get('id') as string;

      this.profile$ = this.fetch$.pipe(
        switchMap(() =>
          profileService.getById(this.id!).pipe(
            tap(profile =>
              profileService.getMe(true).subscribe(me => {
                this.isOwner = me.id === profile.id;
              })
            )
          )
        )
      );

      this.reload();
    });

    route.queryParamMap.subscribe(params => {
      this.isPreview = !!params.get('preview');
    });
  }

  get isEditable() {
    return this.isOwner && !this.isPreview;
  }

  reload() {
    this.fetch$.next(1);
  }
}
