<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Über uns</h1>

    <app-rich-text-field
      [placeholderText]="placeholder"
      formControlName="about" />

    <app-chip-list-field
      [formControl]="form.controls.aboutHighlights"
      [suggestions]="highlightSuggestions"
      [chipSkin]="chipSkin" />

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/schools', id]">
        Abbrechen
      </a>
      <app-action-button
        [disabled]="!form.dirty"
        text="Speichern"
        (onClick)="save()" />
    </div>
  </div>
</form>
