<div
  class="rounded-2xl h-full w-full bg-center bg-no-repeat bg-contain object-center object-cover"
  [ngStyle]="{
    'background-image':
      'url(' +
      schoolHeaderImageUrl +
      '), url(/assets/defaults/background_school.jpeg)'
  }">
  <img
    alt="School Background"
    src="/assets/defaults/background_school.jpeg"
    style="visibility: hidden" />
</div>
