@if (school$ | async; as school) {
  <div class="grid grid-cols-12">
    <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
      <div class="flex flex-col">
        <h1 class="font-bold text-4xl">Schule</h1>
        <div class="flex text-base text-[#B5B6C1]">
          <div>
            Du kannst die Reihenfolge der Standorte mit Klick auf
            <mat-icon matPrefix class="material-symbols-rounded pt-0.5"
              >drag_indicator</mat-icon
            >
            selber wählen!
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        @if (school?.isRoot) {
          <mat-form-field appearance="fill">
            <mat-label>Schulart</mat-label>
            <mat-select [formControl]="schoolTypeControl">
              @for (type of schoolTypes; track $index) {
                <mat-option [value]="type">
                  {{ type }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        <div class="flex flex-col">
          <mat-form-field>
            @if (locations.length === 0) {
              <mat-label>Name der Schule</mat-label>
            } @else {
              <mat-label> Name der Schuleinheit </mat-label>
            }
            <input type="string" [formControl]="schoolName" matInput />
            @if (locations.length === 0) {
              <mat-error> Name der Schule ist erforderlich </mat-error>
            } @else {
              <mat-error> Name der Schuleinheitist erforderlich </mat-error>
            }
          </mat-form-field>
          @if (locations.length > 0) {
            <h4>Standorte</h4>
            <div
              class="flex flex-col"
              cdkDropList
              (cdkDropListDropped)="drop($event)">
              <div class="flex flex-col">
                @for (location of locations; track location; let i = $index) {
                  <div class="flex items-center gap-4">
                    <mat-form-field
                      class="grow"
                      [cdkDragData]="location"
                      [cdkDragDisabled]="location.loading"
                      cdkDrag>
                      <mat-label>Name Standort {{ i + 1 }}</mat-label>
                      <input
                        type="string"
                        matInput
                        [formControl]="location.field"
                        [disabled]="location.loading" />
                      <mat-error
                        >Name des Schulhauses ist erforderlich</mat-error
                      >
                      <mat-icon
                        cdkDragHandle
                        matPrefix
                        class="material-symbols-rounded cursor-grab"
                        >drag_indicator</mat-icon
                      >
                    </mat-form-field>
                    @if (!location.loading) {
                      <button class="pb-4" (click)="removeLocation(i)">
                        <mat-icon class="material-symbols-rounded"
                          >delete</mat-icon
                        >
                      </button>
                    } @else {
                      <div class="pb-4">
                        <mat-spinner diameter="24"></mat-spinner>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          }
          <div>
            <button
              (click)="addLocation()"
              mat-stroked-button
              class="text-bn-primary-blue">
              <mat-icon class="material-symbols-rounded scale-125"
                >add</mat-icon
              >
              <span>Standort hinzufügen</span>
            </button>
          </div>
        </div>
      </div>

      <div class="flex justify-end gap-8">
        <a mat-button color="primary" [routerLink]="['/my-schools']"
          >Abbrechen</a
        >

        <app-action-button
          [disabled]="isActionButtonDisabled()"
          [loading]="isLoading"
          text="Speichern"
          (onClick)="save(school)" />
      </div>
    </div>
  </div>
}
