@if (!showEmptyCard) {
  <strong class="font-bold text-2xl md:text-4xl"> Werte </strong>
  @if (isEditable) {
    <app-edit-button
      class="pl-4"
      routerLink="edit/preferences"></app-edit-button>
  }
  <div class="pt-4">
    <app-chip-list [items]="profile.preferences" chipSkin="general" />
  </div>
}

@if (showEmptyCard && isEditable) {
  <app-empty-card
    title="Werte"
    subtitle="Mach dich interessanter!"
    description="Zeig was dir im täglichen Miteinander besonders wichtig ist."
    link="edit/preferences"></app-empty-card>
}
