import { inject, Injectable } from '@angular/core';
import { UploadProgressEvent } from '@api.video/video-uploader';
import { ApiVideoService } from './api-video.service';
import { ApiVideoAsset } from '../types/core';
import { firstValueFrom, Observable } from 'rxjs';

import { JobService } from './job.service';
import { SchoolService } from './school.service';
import { ConfirmDialogProps } from '../components/confirm-dialog/confirm-dialog.component';
import { FileService } from './files.service';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

export enum MediaVideoServiceActions {
  SCHOOL = 'SCHOOL',
  JOB = 'JOB',
}
type Action = MediaVideoServiceActions.JOB | MediaVideoServiceActions.SCHOOL;

@Injectable({
  providedIn: 'root',
})
export class MediaVideoService {
  readonly acceptTypes: string[] = ['.avi', '.webm', '.mp4', '.wmv', '.mov'];
  readonly deleting: string = 'wird gelöscht...';
  readonly data: ConfirmDialogProps = {
    text: 'Willst Du das Video wirklich löschen?',
    cancelText: 'Abbrechen',
    confirmText: 'Löschen',
  };

  readonly apiVideoService = inject(ApiVideoService);
  readonly jobService = inject(JobService);
  readonly schoolService = inject(SchoolService);
  readonly fileService = inject(FileService);
  readonly imageCompress = inject(NgxImageCompressService);

  uploadProgress$: Observable<UploadProgressEvent> =
    this.apiVideoService.uploadProgress$;

  getProgress(progress: UploadProgressEvent) {
    const p = Math.round((progress.uploadedBytes / progress.totalBytes) * 100);
    return p === 100 ? 'wird verarbeitet...' : `${p || 0} %`;
  }

  async upload(
    event: Event,
    id: string,
    action: Action = MediaVideoServiceActions.SCHOOL,
    posterUploadPath?: string
  ): Promise<ApiVideoAsset | null> {
    try {
      const video = await this.uploadVideoAsset(event, posterUploadPath);

      if (video) {
        action === MediaVideoServiceActions.SCHOOL
          ? await this.schoolService.updateAsync(id, { video })
          : await firstValueFrom(this.jobService.update(id, { video }));
      }

      return video;
    } catch (e) {
      console.error('Error while uploading video', e);
    }

    return null;
  }

  async remove(
    vid: string,
    id: string,
    action: Action = MediaVideoServiceActions.SCHOOL,
    posterPath?: string
  ): Promise<void> {
    try {
      const video = undefined;
      await this.removeVideoAsset(vid, id, action);
      if (posterPath) await this.fileService.remove(posterPath);
      action === MediaVideoServiceActions.SCHOOL
        ? await this.schoolService.updateAsync(id, { video })
        : await firstValueFrom(this.jobService.update(id, { video }));

      return;
    } catch (e) {
      console.error('Error while removing video', e);
    }
  }

  private async uploadVideoAsset(
    event: Event,
    posterUploadPath?: string
  ): Promise<ApiVideoAsset | null> {
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;

    if (files && files.length === 1) {
      const response = await this.apiVideoService.uploadAsync(files[0]);
      const poster =
        response.assets?.thumbnail && posterUploadPath
          ? await this.uploadVideoPoster(
              response.assets.thumbnail,
              posterUploadPath
            )
          : undefined;

      return {
        id: response.videoId,
        hls: response.assets?.hls ?? '',
        mp4: response.assets?.mp4 ?? '',
        poster,
      };
    }

    return null;
  }

  private async uploadVideoPoster(
    url: string,
    path: string
  ): Promise<string | null | undefined> {
    const file = await this.getPoster(url, 3);
    const compressed = await this.imageCompress.compressFile(
      await this.fileService.blobToBase64(file),
      DOC_ORIENTATION.Default,
      75,
      75,
      1280,
      720
    );
    const bucketFile = await this.fileService.uploadAsync(
      path,
      this.fileService.dataURLtoBlob(compressed),
      'poster.jpg',
      true
    );

    return bucketFile.url;
  }

  private async removeVideoAsset(
    vid: string,
    id: string,
    action: Action
  ): Promise<void> {
    return firstValueFrom(
      action === MediaVideoServiceActions.SCHOOL
        ? this.apiVideoService.remove(vid, id)
        : this.apiVideoService.removeJobVideo(vid, id)
    );
  }

  private async getPoster(url: string, retries: number = 3): Promise<Blob> {
    return fetch(url).then(res => {
      if (res.ok) {
        return res.blob();
      }
      if (retries > 0) {
        return this.getPoster(url, retries - 1);
      }
      throw new Error(`${res.status}`);
    });
  }
}
