import { Component, Input, OnInit } from '@angular/core';
import {
  getChipSkinFromSchoolType,
  getPublicFileUrl,
} from '../../../../core/helpers';
import { School } from '../../../../types/school';
import { SchoolService } from '../../../../services/school.service';
import { ChipSkin } from 'src/app/components/chip/chip.component';

@Component({
  selector: 'app-school-header',
  templateUrl: './school-header.component.html',
  styleUrls: ['./school-header.component.scss'],
})
export class SchoolHeaderComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isEditable!: boolean;

  hideAvatar = false;
  chipSkin: ChipSkin = 'school_public';

  constructor(private schoolService: SchoolService) {}

  ngOnInit(): void {
    this.hideAvatar = !this.school.avatar && !this.isEditable;
    this.chipSkin = getChipSkinFromSchoolType(this.school.type);
  }

  getAvatarUrl(school: School) {
    if (school.avatar && school.id) {
      return getPublicFileUrl('schools', school.id, 'avatar');
    }

    return 'assets/defaults/school_avatar.svg';
  }

  getBackgroundUrl(school: School) {
    if (school.backgroundImage && school.id) {
      return getPublicFileUrl('schools', school.id, 'backgroundImage');
    }

    return 'assets/defaults/background_school.jpeg';
  }

  getSchoolLevelsTitle(school: School) {
    return this.schoolService.getLevelsTitle(
      school.levels || [],
      school.levelDescriptions || []
    );
  }
}
