import { Component, Input, OnInit } from '@angular/core';
import { Profile, WorkExperience } from '../../../../types/profile';
import { format } from 'date-fns';
import { translateMonths } from '../../../../core/helpers';

@Component({
  selector: 'app-profile-work-experiencs-card',
  templateUrl: './profile-work-experiencs-card.component.html',
  styleUrls: ['./profile-work-experiencs-card.component.scss'],
})
export class ProfileWorkExperiencsCardComponent implements OnInit {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isEditable!: boolean;

  workeExperiences: WorkExperience[] = [];
  showAll = false;
  amount = 3;

  get showEmptyCard() {
    return (
      !this.profile.workExperiences || this.profile.workExperiences.length < 1
    );
  }

  ngOnInit(): void {
    this.workeExperiences =
      this.profile.workExperiences?.sort((a, b) => {
        if (!a.endDate) return -1;
        if (!b.endDate) return 1;
        return b.endDate < a.endDate ? -1 : 1;
      }) || [];
  }

  getStartDate(workExperience: WorkExperience) {
    if (!workExperience.startDate) return '';
    const month = translateMonths(
      format(new Date(workExperience.startDate), 'MMMM')
    ).toUpperCase();
    const year = format(new Date(workExperience.startDate), 'Y');
    return `${month} ${year}`;
  }

  getEndDate(workExperience: WorkExperience) {
    if (!workExperience.endDate) return 'HEUTE';
    const month = translateMonths(
      format(new Date(workExperience.endDate), 'MMMM')
    ).toUpperCase();
    const year = format(new Date(workExperience.endDate), 'Y');
    return `${month} ${year}`;
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
    this.amount = this.showAll ? this.profile.workExperiences?.length || 0 : 3;
  }
}
