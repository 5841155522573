import { Component, inject, Input } from '@angular/core';
import { Job } from '../../../../types/job';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { JobService } from '../../../../services/job.service';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-job-share-button',
  standalone: false,
  templateUrl: './job-share-button.component.html',
  styleUrl: './job-share-button.component.scss',
})
export class JobShareButtonComponent {
  @Input({ required: true }) job!: Job;

  readonly router = inject(Router);
  readonly jobService = inject(JobService);
  readonly analytics = inject(Analytics);
  canShare = navigator.share !== undefined;

  getJobLevels(job: Job) {
    return this.jobService.getLevelsTitle(
      job.schoolLevels || [],
      job.schoolLevelDescriptions || []
    );
  }

  getTitle() {
    const jobType = this.job.isFullTimeJob
      ? 'Festanstellung'
      : 'Stellvertretung';
    return `Schau dir diese spannende ${jobType} auf Bildigsnetz an, die ich gerade entdeckt habe!\n`;
  }

  getText() {
    if (this.job.isFullTimeJob) {
      const date = new Date(this.job.durationFrom ?? new Date());
      const startDate =
        new Date().toDateString() > date.toISOString()
          ? format(date, 'dd.MM.yyyy')
          : 'Ab sofort';

      const lines = [];
      lines.push(`${this.job.schoolName}, ${this.job.schoolAddress?.city}`);
      lines.push(`${this.job.function}, ${this.getJobLevels(this.job)}`);

      if (this.job.activityRangeInPercentage?.from) {
        const from = this.job.activityRangeInPercentage?.from || '0';
        const to = this.job.activityRangeInPercentage?.to || from;
        if (from === to || !to) {
          lines.push(`Pensum: ${from}%`);
        } else {
          lines.push(`Pensum: ${from}% bis ${to}%`);
        }
      } else {
        if (this.job.activityRangeInHours?.from) {
          const from = this.job.activityRangeInHours?.from || '0';
          const to = this.job.activityRangeInHours?.to || from;
          if (from === to || !to) {
            lines.push(`Lektionen: ${from}`);
          } else {
            lines.push(`Lektionen: ${from} bis ${to}`);
          }
        }
      }

      lines.push(startDate);

      return lines.join('\n');
    }

    return `\n${this.job.schoolName}, ${this.job.schoolAddress?.city}
${this.jobService.getScheduleText(this.job)}
${this.job.function}, ${this.getJobLevels(this.job)}
${this.jobService.getSubstitutionPeriodText(this.job)}`;
  }

  getURL() {
    return this.router
      .createUrlTree(['/jobs', this.job.id], {
        queryParams: { share: true },
      })
      .toString();
  }
  async onShare() {
    const url = this.getURL();
    const text = `${this.getTitle()}\r${this.getText()}\n`;

    logEvent(this.analytics, 'share_link', { url });

    if (this.canShare) {
      await navigator.share({ text, url });
    }
  }
}
