@if (isPreview) {
  <div class="flex flex-col items-end py-2 md:py-3">
    <app-top-bar-item icon="close" (action)="executeClosePreviewing()" />
    <!-- Add profile share button here -->
  </div>
} @else {
  <div class="flex justify-between items-center py-2 md:py-3">
    <a
      [ngClass]="{ invisible: isOwner }"
      mat-button
      [disabled]="actionInProgress !== null"
      [routerLink]="['..']"
      queryParamsHandling="preserve">
      <mat-icon class="material-symbols-rounded">keyboard_backspace</mat-icon>
      Alle Ergebnisse
    </a>

    @if (isOwner) {
      <div class="flex gap-2">
        <app-top-bar-preview
          [disabled]="actionInProgress !== null"
          (preview)="executePreviewing()" />
      </div>
    } @else {
      <!-- Add profile share button here -->
    }
  </div>
}
