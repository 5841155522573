import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile } from 'src/app/types/profile';

@Component({
  selector: 'app-profile-top-bar',
  templateUrl: './profile-top-bar.component.html',
  styleUrl: './profile-top-bar.component.scss',
})
export class ProfileTopBarComponent {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isOwner!: boolean;
  @Input({ required: true }) isPreview!: boolean;

  actionInProgress: 'closePreview' | 'preview' | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async executePreviewing() {
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { preview: true },
      queryParamsHandling: 'merge',
    });
  }

  async executeClosePreviewing() {
    const currentParams = { ...this.route.snapshot.queryParams };
    delete currentParams['preview'];

    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: currentParams,
    });
  }
}
