import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Profile } from '../../../../types/profile';

@Component({
  selector: 'app-profile-progress-card',
  templateUrl: './profile-progress-card.component.html',
  styleUrls: ['./profile-progress-card.component.scss'],
})
export class ProfileProgressCardComponent implements OnChanges {
  @Input({ required: true }) profile!: Profile;

  // Berufliches
  hasWorkExperience = false;
  hasEducation = false;
  hasQualifications = false;
  hasMemberships = false;

  // Persönliches
  hasPreferences = false;
  hasAboutMe = false;
  hasLanguages = false;

  checklist: boolean[] = [];
  progressPercentage = 0;

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['profile']) {
      await this.calculateProgress();
      this.calculateProgressInPercent();
    }
  }

  async calculateProgress(): Promise<void> {
    this.hasWorkExperience =
      !!this.profile.workExperiences && this.profile.workExperiences.length > 0;
    this.checklist.push(this.hasWorkExperience);

    this.hasEducation =
      !!this.profile.educations && this.profile.educations.length > 0;
    this.checklist.push(this.hasEducation);

    this.hasQualifications =
      (!!this.profile.subjects && this.profile.subjects.length > 0) ||
      (!!this.profile.certifications && this.profile.certifications.length > 0);
    this.checklist.push(this.hasEducation);

    this.hasPreferences =
      !!this.profile.preferences && this.profile.preferences.length > 0;
    this.checklist.push(this.hasPreferences);

    this.hasAboutMe = !!this.profile.about;
    this.checklist.push(this.hasAboutMe);

    this.hasLanguages =
      !!this.profile.languages && this.profile.languages.length > 0;
    this.checklist.push(this.hasLanguages);

    this.hasMemberships = !!this.profile.schools?.length;
    this.checklist.push(this.hasMemberships);
  }

  calculateProgressInPercent() {
    const numberOfCheckedItems = this.checklist.filter(item => item).length;
    const numberOfItems = this.checklist.length;

    this.progressPercentage = Math.ceil(
      (numberOfCheckedItems / numberOfItems) * 100
    );
  }
}
