import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SchoolService } from '../../../../services/school.service';
import { School } from '../../../../types/school';
import { convertRichTextToPlainText } from 'src/app/core/richText';
import { ChipSkin } from 'src/app/components/chip/chip.component';
import { getChipSkinFromSchoolType } from 'src/app/core/helpers';

@Component({
  selector: 'app-school-weoffer-form',
  templateUrl: './school-weoffer-form.component.html',
  styleUrls: ['./school-weoffer-form.component.scss'],
})
export class SchoolWeofferFormComponent {
  id: string;
  school$: Observable<School>;

  form = this.fb.group({
    whatWeOffer: ['', []],
    whatWeOfferHighlights: [],
  });

  highlightSuggestions = [
    'Gratis Kaffee',
    'Guter Teamspirit',
    'Sport über Mittag',
    'Obst für Mitarbeitende',
    'Gratis Parkplätze',
    'Grosszügige Weiterbildungsbeiträge',
    'Sitzungspflicht nur an Arbeitstagen',
    'Moderne Infrastruktur',
    'Digital top ausgestattet',
    'Unterstützendes Umfeld',
    'Grosse Klassenzimmer',
    'Gruppenräume',
    'Unterstützendes Team',
    'Unterstützende Schulleitung',
    'Pädagogische Freiräume',
    'Gute Anschliessung an ÖV',
    'Zentral',
  ];
  chipSkin: ChipSkin = 'school_public';

  constructor(
    route: ActivatedRoute,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = this.schoolService.getById(this.id);

    this.school$.subscribe(school => {
      this.form.setValue({
        whatWeOffer: school.whatWeOffer_html || '',
        whatWeOfferHighlights: school.whatWeOfferHighlights || [],
      } as any);

      this.chipSkin = getChipSkinFromSchoolType(school.type);
    });
  }

  async save() {
    const { whatWeOffer } = this.form.getRawValue();
    const payload = {
      whatWeOffer: convertRichTextToPlainText(whatWeOffer) || '',
      whatWeOffer_html: whatWeOffer || '',
      whatWeOfferHighlights:
        this.form.get('whatWeOfferHighlights')?.value || [],
    };

    this.schoolService.update(this.id, payload).subscribe(async () => {
      await this.router.navigate(['schools', this.id]);
    });
  }
}
