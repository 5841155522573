import { inject, Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionData,
  doc,
  Firestore,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import {
  MembershipsNotificationData,
  MembershipsNotificationEnum,
} from '../types/notification';
import { firstValueFrom, Observable, of } from 'rxjs';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { School } from '../types/school';
import { Profile } from '../types/profile';

@Injectable({
  providedIn: 'root',
})
export class MembershipsService {
  private readonly auth = inject(Auth);
  private readonly db = inject(Firestore);

  private readonly membershipsNotificationFunc: (
    data: MembershipsNotificationData
  ) => Observable<void>;

  constructor(functions: Functions) {
    this.membershipsNotificationFunc = httpsCallableData<
      MembershipsNotificationData,
      void
    >(functions, 'membershipsNotification');
  }

  async dropMemberships({
    schoolIds,
    userId,
    sendRemovedByHimselfNotification,
    sendRemovedByAdminNotification,
  }: {
    schoolIds?: string[];
    userId?: string;
    sendRemovedByHimselfNotification?: boolean;
    sendRemovedByAdminNotification?: boolean;
  }) {
    try {
      const uid = userId ?? this.auth.currentUser?.uid;

      if (uid && !!schoolIds?.length) {
        for (const sid of schoolIds) {
          await updateDoc(doc(this.db, 'profiles', uid), {
            schools: arrayRemove(sid),
          });
          await updateDoc(doc(this.db, 'schools', sid), {
            members: arrayRemove(uid),
          });
        }

        if (sendRemovedByHimselfNotification) {
          await firstValueFrom(
            this.membershipsNotificationFunc({
              schoolIds: schoolIds,
              userId: uid,
              action: MembershipsNotificationEnum.REMOVED_BY_HIMSELF,
            })
          );
        }

        if (sendRemovedByAdminNotification) {
          await firstValueFrom(
            this.membershipsNotificationFunc({
              schoolIds: schoolIds,
              userId: uid,
              action: MembershipsNotificationEnum.REMOVED_BY_ADMIN,
            })
          );
        }
      }
    } catch (e: unknown) {
      console.error('[dropMemberships failed]', e);
    }
  }

  async updateMemberships({
    schoolIds,
    userId,
    sendAddedByHimselfNotification,
  }: {
    schoolIds?: string[];
    userId?: string;
    sendAddedByHimselfNotification?: boolean;
  }): Promise<void> {
    try {
      const uid = userId ?? this.auth.currentUser?.uid;

      if (uid && !!schoolIds?.length) {
        for (const sid of schoolIds) {
          await updateDoc(doc(this.db, 'profiles', uid), {
            schools: arrayUnion(sid),
          });
          await updateDoc(doc(this.db, 'schools', sid), {
            members: arrayUnion(uid),
          });
        }

        if (sendAddedByHimselfNotification) {
          await firstValueFrom(
            this.membershipsNotificationFunc({
              schoolIds: schoolIds,
              userId: uid,
              action: MembershipsNotificationEnum.ADDED_BY_HIMSELF,
            })
          );
        }
      }
    } catch (e: unknown) {
      console.error('[updateMemberships failed]', e);
    }
  }

  async setBelongsTo({
    schoolIds,
    droppableSchoolIds,
    userId,
    sendAddedByHimselfNotification,
    sendRemovedByHimselfNotification,
    sendRemovedByAdminNotification,
  }: {
    schoolIds: string[];
    droppableSchoolIds: string[];
    userId?: string;
    sendAddedByHimselfNotification?: boolean;
    sendRemovedByHimselfNotification?: boolean;
    sendRemovedByAdminNotification?: boolean;
  }): Promise<void> {
    if (userId) {
      await this.dropMemberships({
        schoolIds: droppableSchoolIds,
        userId,
        sendRemovedByHimselfNotification,
        sendRemovedByAdminNotification,
      });
      await this.updateMemberships({
        schoolIds,
        userId,
        sendAddedByHimselfNotification,
      });
    }
  }

  getMembershipSchools(userId?: string): Observable<School[]> {
    if (userId) {
      const ref = query(
        collection(this.db, 'schools'),
        where('members', 'array-contains', userId),
        where('isPublic', '==', true)
      );
      return collectionData(ref, { idField: 'id' }) as Observable<School[]>;
    }
    return of([]);
  }

  getMembershipProfiles(schoolId?: string): Observable<Profile[]> {
    if (schoolId) {
      const ref = query(
        collection(this.db, 'profiles'),
        where('schools', 'array-contains', schoolId),
        where('isPublic', '==', true)
      );
      return collectionData(ref, { idField: 'id' }) as Observable<Profile[]>;
    }
    return of([]);
  }
}
