import { Component, Input } from '@angular/core';
import { ChipSkin } from 'src/app/components/chip/chip.component';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrl: './chip-list.component.scss',
})
export class ChipListComponent {
  // Assign empty array if no value is provided
  @Input({ transform: (value: string[] | undefined) => (value ? value : []) })
  items: string[] = [];
  @Input() chipSkin: ChipSkin = 'general';
}
