import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  viewChild,
} from '@angular/core';
import Swiper from 'swiper';
import { Profile } from '../../../../types/profile';
import { getPublicFileUrl } from '../../../../core/helpers';
import { Auth } from '@angular/fire/auth';
import { isBefore } from 'date-fns';
import { Router } from '@angular/router';
import { Navigation, Scrollbar } from 'swiper/modules';
import { MatDialog } from '@angular/material/dialog';
import type { SwiperOptions } from 'swiper/types/swiper-options';
import { SchoolMembershipsCardDialogComponent } from './components/school-memberships-card-dialog/school-memberships-card-dialog.component';
import { MembershipsService } from '../../../../services/memberships.service';

@Component({
  selector: 'app-school-memberships-card',
  templateUrl: './school-memberships-card.component.html',
  styleUrl: './school-memberships-card.component.scss',
})
export class SchoolMembershipsCardComponent {
  readonly sid = input.required<string>();
  readonly profiles = input.required<Profile[]>();
  readonly hasProfiles = computed(() => !!this.profiles().length);
  readonly isOwner = input.required<boolean>();
  readonly isPreview = input.required<boolean>();

  showBanner: boolean = false;

  readonly auth = inject(Auth);
  readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);
  private readonly membershipsService = inject(MembershipsService);

  swiper?: Swiper;
  swiperRef = viewChild<ElementRef>('swiperRef');
  readonly swiperOptions: SwiperOptions = {
    modules: [Navigation, Scrollbar],
    freeMode: true,
    slidesPerView: 'auto',
    grabCursor: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      dragClass: 'swiper-scrollbar-drag swiper-scrollbar-cursor-drag',
    },
    navigation: {
      nextEl: '.next',
      prevEl: '.prev',
    },
    breakpoints: {
      0: {
        spaceBetween: 8,
      },
      768: {
        spaceBetween: 20,
      },
    },
  };
  readonly bannerDueDate: Date = new Date('2026-04-30');

  constructor() {
    effect(() => {
      new Swiper('#membership-swiper', this.swiperOptions);
      this.swiper = this.swiperRef()?.nativeElement.swiper;

      this.showBanner =
        !this.isOwner() &&
        !this.isPreview() &&
        !this.profiles().some(
          profile => profile.id === this.auth.currentUser?.uid
        ) &&
        isBefore(new Date(), this.bannerDueDate);
    });
  }

  getAvatarUrl(profile: Profile) {
    if (profile.avatar && profile.id) {
      return getPublicFileUrl('profiles', profile.id, 'avatar');
    }

    return 'assets/defaults/avatar.svg';
  }

  dropMembership(profile: Profile): void {
    const dialogRef = this.dialog.open(SchoolMembershipsCardDialogComponent, {
      data: { profile },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.membershipsService.dropMemberships({
          schoolIds: [this.sid()],
          userId: profile.id,
          sendRemovedByAdminNotification: true,
        });
      }
    });
  }
}
