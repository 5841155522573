@if (isVisible) {
  <article class="info-banner">
    <header class="info-banner__header">
      <app-chip skin="new_feature" />
      @if (closable()) {
        <button class="info-banner__header__close" (click)="closeBanner()">
          <mat-icon class="material-symbols-rounded">close</mat-icon>
          <span class="sr-only">Schließen</span>
        </button>
      }
    </header>
    <main class="info-banner__content">
      <strong class="info-banner__content__headline">
        {{ headline() }}
      </strong>
      <div class="info-banner__content__container">
        <ng-content />
      </div>
    </main>
  </article>
}
