import { AfterViewInit, Component, effect, input, output } from '@angular/core';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrl: './info-banner.component.scss',
})
export class InfoBannerComponent implements AfterViewInit {
  readonly visible = input.required<boolean>();
  readonly storageKey = input.required<string>();
  readonly closable = input<boolean>(false);
  readonly headline = input.required<string>();
  readonly onClose = output<void>();

  isVisible: boolean = false;
  private _timeout?: NodeJS.Timeout;

  constructor() {
    effect(() => {
      this._timeout = setTimeout(() => {
        this.isVisible =
          this.visible() && localStorage.getItem(this.storageKey()) === null;
      }, 150);
    });
  }

  ngAfterViewInit(): void {
    clearTimeout(this._timeout);
  }

  closeBanner(): void {
    localStorage.setItem(this.storageKey(), 'true');
    this.isVisible = false;
    this.onClose.emit();
  }
}
