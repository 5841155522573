import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ChipSkin =
  | 'general'
  | 'school_public'
  | 'school_private'
  | 'school_special'
  | 'job_fulltime'
  | 'job_substitute'
  | 'hospitation'
  | 'new_feature'
  | 'not_defined';

const chipSkins = {
  general: {
    bg: 'bg-chip-general-light',
    text: 'text-chip-general-dark',
  },
  school_public: {
    bg: 'bg-chip-school-public-light',
    text: 'text-chip-school-public-dark',
  },
  school_private: {
    bg: 'bg-chip-school-private-light',
    text: 'text-chip-school-private-dark',
  },
  school_special: {
    bg: 'bg-chip-school-special-light',
    text: 'text-chip-school-special-dark',
  },
  job_fulltime: {
    bg: 'bg-job-fulltime-dark',
    text: 'text-chip-job-fulltime-light',
  },
  job_substitute: {
    bg: 'bg-job-substitute-dark',
    text: 'text-chip-job-substitute-light',
  },
  hospitation: {
    bg: 'bg-chip-hospitation-dark',
    text: 'text-chip-hospitation-light',
  },
  new_feature: {
    bg: 'bg-chip-new-feature-dark',
    text: 'text-chip-new-feature-light',
  },
  not_defined: {
    bg: 'bg-chip-not-defined-light',
    text: 'text-chip-not-defined-dark',
  },
};

export function getChipSkinColors(chipSkin: ChipSkin): {
  bg: string;
  text: string;
} {
  return chipSkins[chipSkin] || chipSkins.general;
}

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent implements OnInit {
  @Input() skin: ChipSkin = 'general';
  @Input() text: string = '';
  @Input() removable = false;
  @Output() removed = new EventEmitter<void>();

  displayText = '';
  colors: { bg: string; text: string } = { bg: '', text: '' };

  ngOnInit(): void {
    this.colors = getChipSkinColors(this.skin);
    this.displayText = this.skin === 'new_feature' ? 'Neu!' : this.text;
  }
}
