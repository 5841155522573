<address class="contact-card-item">
  @if (contact().isAdmin) {
    <a [routerLink]="['/profile', contact().userId]">
      <app-round-image
        [src]="getPublicFileUrl('profiles', contact().userId, 'avatar')" />
    </a>
  } @else {
    <app-round-image [src]="contact().avatarUrl" />
  }
  <p class="contact-card-item__contact">
    <strong>{{ contact().name }}</strong>
    <span>{{ contact().jobFunction }}</span>
    <span class="pt-0.5">{{ contact().phone }}</span>
    <a class="contact-card-item__contact__link" #link>{{ email() }}</a>
  </p>
  @if (contact().isAdmin && !!me && me.id !== contact().userId) {
    <aside class="contact-card-item__sidebar">
      <app-edit-button
        icon="forum"
        (click)="openChat()"
        [disabled]="isPreview()" />
    </aside>
  }
</address>
