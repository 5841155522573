import { Component, Input, OnInit } from '@angular/core';
import { School } from '../../../../types/school';
import { isRichTextFieldEmpty } from 'src/app/core/richText';
import { getChipSkinFromSchoolType } from 'src/app/core/helpers';
import { ChipSkin } from 'src/app/components/chip/chip.component';

@Component({
  selector: 'app-school-about-card',
  templateUrl: './school-about-card.component.html',
  styleUrls: ['./school-about-card.component.scss'],
})
export class SchoolAboutCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isEditable!: boolean;

  subtitle = '';
  description = '';
  showEmptyCard = false;
  chipSkin: ChipSkin = 'general';

  ngOnInit(): void {
    this.subtitle = this.school.isRoot
      ? 'Was macht deine Schuleinheit aus?'
      : 'Was macht diesen spezifischen Schulstandort aus?';

    this.description = this.school.isRoot
      ? 'Steigere die Attraktivität deiner Schule, indem du interessante Fakten teilst. Hier kannst du Bezug auf die gesamte Schuleinheit nehmen.'
      : 'Hier nimmst du Bezug auf standortspezifische Eigenschaften. Was ist speziell an diesem Standort? Weicht er in irgendeiner Form von der restlichen Schuleinheit ab?';

    this.showEmptyCard =
      isRichTextFieldEmpty(this.school.about_html) &&
      (!this.school.aboutHighlights ||
        this.school.aboutHighlights.length === 0);

    this.chipSkin = getChipSkinFromSchoolType(this.school.type);
  }
}
