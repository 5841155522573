import { Component, Input, OnInit } from '@angular/core';
import { Job } from '../../../../types/job';
import { isRichTextFieldEmpty } from 'src/app/core/richText';

@Component({
  selector: 'app-job-we-offer-card',
  templateUrl: './job-we-offer-card.component.html',
  styleUrls: ['./job-we-offer-card.component.scss'],
})
export class JobWeOfferCardComponent implements OnInit {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  showEmptyCard = false;

  ngOnInit(): void {
    this.showEmptyCard =
      isRichTextFieldEmpty(this.job.weOffer_html) &&
      (!this.job.whatWeOfferHighlights ||
        this.job.whatWeOfferHighlights.length === 0);
  }
}
