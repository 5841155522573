import { Injectable } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import {
  UploadProgressEvent,
  VideoUploadResponse,
  VideoUploader,
} from '@api.video/video-uploader';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { VideoUploadToken } from '../types/video';
import { BitrateOptions } from '@videogular/ngx-videogular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiVideoService {
  readonly defaultProgressValue: UploadProgressEvent = {
    uploadedBytes: 0,
    totalBytes: 0,
    currentChunk: 0,
    chunksCount: 0,
    chunksBytes: 0,
    currentChunkUploadedBytes: 0,
  };

  // Default API Video Bitrates - https://api.video/what-is/bitrate/
  readonly bitrates: BitrateOptions[] = [
    {
      qualityIndex: 0,
      width: 0,
      height: 0,
      bitrate: 0,
      mediaType: 'video',
      label: 'AUTO',
    },
    {
      qualityIndex: 1,
      width: 426,
      height: 240,
      bitrate: 250000,
      mediaType: 'video',
      label: '240P',
    },
    {
      qualityIndex: 2,
      width: 640,
      height: 360,
      bitrate: 800000,
      mediaType: 'video',
      label: '360P',
    },
    {
      qualityIndex: 3,
      width: 854,
      height: 480,
      bitrate: 1400000,
      mediaType: 'video',
      label: '480P',
    },
    {
      qualityIndex: 4,
      width: 1280,
      height: 720,
      bitrate: 2600000,
      mediaType: 'video',
      label: '720P',
    },
    {
      qualityIndex: 5,
      width: 1920,
      height: 1080,
      bitrate: 4400000,
      mediaType: 'video',
      label: '1080P',
    },
    {
      qualityIndex: 6,
      width: 3840,
      height: 2160,
      bitrate: 16000000,
      mediaType: 'video',
      label: '2160P (4K)',
    },
  ];

  private uploadProgressSubject = new BehaviorSubject<UploadProgressEvent>(
    this.defaultProgressValue
  );
  private createVideoToken: () => Observable<VideoUploadToken>;
  private deleteVideo: (data: {
    videoId: string;
    schoolId: string;
  }) => Observable<void>;
  private deleteJobVideo: (data: {
    videoId: string;
    jobId: string;
  }) => Observable<void>;

  uploadProgress$ = this.uploadProgressSubject.asObservable();

  constructor(functions: Functions) {
    this.createVideoToken = httpsCallableData(
      functions,
      'createvideotoken',
      {}
    );
    this.deleteVideo = httpsCallableData(functions, 'deletevideo', {});
    this.deleteJobVideo = httpsCallableData(functions, 'deletejobvideo', {});
  }

  async uploadAsync(file: File) {
    return new Promise<VideoUploadResponse>(async resolve => {
      // eslint-disable-next-line prefer-const
      let response: VideoUploadResponse;

      this.uploadProgressSubject.next(this.defaultProgressValue);
      const { token } = await lastValueFrom(this.createVideoToken());
      if (!token) {
        throw new Error('No token');
      }

      const uploader = new VideoUploader({
        uploadToken: token,
        file,
      });

      uploader.onProgress(e => {
        this.uploadProgressSubject.next(e);
      });

      uploader.onPlayable(() => {
        resolve(response);
      });

      response = await uploader.upload();
    });
  }

  remove(videoId: string, schoolId: string) {
    return this.deleteVideo({ videoId, schoolId });
  }

  removeJobVideo(videoId: string, jobId: string) {
    return this.deleteJobVideo({ videoId, jobId });
  }
}
