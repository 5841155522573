<div
  [ngClass]="{ 'root-school': school.isRoot }"
  class="flex flex-col border-solid border-[#B5B6C1] border rounded-3xl p-1.5 hover:border-[#fff] school-card cursor-pointer h-[26rem]">
  <div class="relative">
    <img
      alt="School Background"
      class="h-52 w-full rounded-[1.35rem] object-center object-cover"
      [src]="getBackgroundUrl(school)" />
    @if (school.avatar) {
      <img
        alt="School Avatar"
        class="h-20 w-20 ml-4 mb-4 rounded-3xl absolute bottom-0"
        [src]="getAvatarUrl(school)" />
    }
  </div>
  <div class="px-4 flex flex-col">
    <div class="pt-8 pb-7 flex items-center justify-between">
      <app-chip [skin]="chipSkin" [text]="school.type!"></app-chip>
      @if (school.isRoot) {
        <mat-icon svgIcon="custom_schuleinheit" title="Schuleinheit"></mat-icon>
      }
    </div>
    <span class="font-bold text-base mb-1 line-clamp-2 text-ellipsis">
      {{ school.name }}
    </span>
    <span class="text-[0.875rem] mb-1 line-clamp-1 text-ellipsis">
      {{ getSchoolTitle(school) }}
    </span>
    <span class="text-[0.875rem] bright-font line-clamp-1 text-ellipsis">
      {{ getSchoolLocation(school) }}
    </span>
  </div>
</div>
