@if (hasProfiles()) {
  <section class="school-memberships-card">
    <header class="school-memberships-card__header">
      <strong class="school-memberships-card__header__headline">
        Mitarbeitende
      </strong>
      @if (showBanner) {
        <app-chip skin="new_feature" />
      }
    </header>
    @if (showBanner) {
      <article class="school-memberships-card__banner">
        Ab jetzt hast du die Möglichkeit, im Schulprofil deiner Schule angezeigt
        zu werden! Füge deine Schule einfach in deinem persönlichen Profil
        hinzu.
        <a
          [routerLink]="
            auth.currentUser
              ? ['/me', auth.currentUser.uid, 'edit', 'school']
              : ['/login']
          "
          class="text-bn-primary-blue hover:text-bn-primary-blue-hover hover:underline">
          Jetzt hinzufügen
        </a>
      </article>
    }
    <main
      id="membership-swiper"
      class="swiper school-memberships-card__swiper"
      #swiperRef>
      <div class="swiper-wrapper school-memberships-card__swiper__wrapper">
        @for (profile of profiles(); track profile.id) {
          <article
            class="swiper-slide school-memberships-card__swiper__wrapper__slide">
            @if (this.isOwner() && !this.isPreview()) {
              <button
                class="school-memberships-card__swiper__wrapper__slide__button"
                (click)="dropMembership(profile)">
                <mat-icon class="material-symbols-rounded">close</mat-icon>
                <span class="sr-only">Profil entfernen</span>
              </button>
            }
            <figure
              class="school-memberships-card__swiper__wrapper__slide__content"
              (click)="router.navigate(['/profile', profile.id])">
              <img
                [alt]="profile.name"
                [src]="getAvatarUrl(profile)"
                class="school-memberships-card__swiper__wrapper__slide__content__avatar" />
              <figcaption
                class="school-memberships-card__swiper__wrapper__slide__content__caption">
                {{ profile.name }}
              </figcaption>
            </figure>
          </article>
        }
      </div>
    </main>
    <aside class="school-memberships-card__meta">
      <div class="school-memberships-card__meta__scrollbar">
        <div class="swiper-scrollbar"></div>
      </div>
      <div class="school-memberships-card__meta__nav">
        <button
          class="school-memberships-card__meta__nav__button prev"
          [disabled]="swiper?.isBeginning">
          <mat-icon
            class="material-symbols-rounded school-memberships-card__meta__nav__button__icon">
            chevron_left
          </mat-icon>
        </button>
        <button
          class="school-memberships-card__meta__nav__button next"
          [disabled]="swiper?.isEnd">
          <mat-icon
            class="material-symbols-rounded school-memberships-card__meta__nav__button__icon">
            chevron_right
          </mat-icon>
        </button>
      </div>
    </aside>
  </section>
}
