@if (!showEmptyCard) {
  <div class="flex justify-between md:justify-normal">
    <div class="flex flex-row flex-wrap gap-2 text-sm pb-2 md:pb-0 w-full">
      @if (school.isRoot) {
        <div
          [ngClass]="[chipColors.bg, chipColors.text]"
          class="base-chip-with-icon gap-3 px-4">
          <mat-icon
            class="material-symbols-rounded mb-1 self-center scale-[105%] md:scale-[130%]"
            svgIcon="custom_address_base"></mat-icon>
          @if (numberOfLocations === 1) {
            <div class="slight-shadow pt-1 md:pt-0.5">
              <strong class="font-bold">1</strong>
              Standort
            </div>
          }
          @if (numberOfLocations > 1) {
            <div class="slight-shadow pt-1 md:pt-0.5">
              <strong class="font-bold">
                {{ numberOfLocations }}
              </strong>
              Standorte
            </div>
          }
        </div>
      }
      @if (!!school.countEmployees) {
        <div
          [ngClass]="[chipColors.bg, chipColors.text]"
          class="base-chip-with-icon gap-6 px-4">
          <mat-icon
            class="self-center scale-[170%] md:scale-[210%] mb-1 pl-1"
            svgIcon="custom_teachers_base"></mat-icon>
          <div class="slight-shadow pt-1 md:pt-0.5">
            <strong class="font-bold">{{ school.countEmployees }}</strong>
            Mitarbeitende
          </div>
        </div>
      }
      @if (!!school.countStudents) {
        <div
          [ngClass]="[chipColors.bg, chipColors.text]"
          class="base-chip-with-icon gap-4 pr-4 pl-6">
          <mat-icon
            class="scale-[130%] md:scale-[160%]"
            svgIcon="custom_students"></mat-icon>
          <div class="slight-shadow pt-1 md:pt-0.5">
            <strong class="font-bold">{{ school.countStudents }}</strong>
            Lernende
          </div>
        </div>
      }
      @if (!!school.address?.street) {
        <a
          [ngClass]="[chipColors.bg]"
          class="base-chip-with-icon gap-1 pl-2 pr-4 text-bn-primary-blue"
          [href]="getGmapsLink()"
          target="_blank">
          <mat-icon
            class="material-symbols-rounded self-center scale-90 md:scale-110"
            >location_on</mat-icon
          >
          <div class="slight-shadow pt-1 md:pt-0.5">
            {{ getLocationText() }}
          </div>
        </a>
      }
      @if (school.isRoot && !!school.address?.website) {
        <a
          [ngClass]="[chipColors.bg]"
          class="base-chip-with-icon gap-2 pl-3 pr-4 text-bn-primary-blue"
          [href]="getSchoolWebsite()"
          target="_blank">
          <mat-icon
            class="self-center scale-[80%] md:scale-100"
            svgIcon="custom_website_base"></mat-icon>
          <div class="slight-shadow pt-1 md:pt-0.5">
            <div>Website</div>
          </div>
        </a>
      }
      @if (!!rootSchool?.cooperatingCollegeOfEducation) {
        <a
          [ngClass]="[chipColors.bg]"
          class="base-chip-with-icon gap-3 px-4 text-bn-primary-blue"
          [href]="
            getCollegeOfEducationWebsite(
              rootSchool?.cooperatingCollegeOfEducation!
            )
          "
          target="_blank">
          <mat-icon
            class="self-center md:scale-125"
            svgIcon="custom_coop-ph_base"></mat-icon>
          <div class="slight-shadow pt-1 md:pt-0.5">
            Kooperationsschule {{ rootSchool?.cooperatingCollegeOfEducation }}
          </div>
        </a>
      }
    </div>
    <div class="self-end">
      @if (isEditable) {
        <app-edit-button color="dark" routerLink="edit/info" />
      }
    </div>
  </div>
}

@if (showEmptyCard && isEditable) {
  <a class="grey" mat-stroked-button color="primary" routerLink="edit/info">
    <mat-icon class="material-symbols-rounded scale-125">add</mat-icon>
    <span> Steckbrief hinzufügen </span>
  </a>
}
