<section class="school-memberships-card-dialog">
  <header class="school-memberships-card-dialog__header">
    <img
      [alt]="data.profile.name"
      [src]="getAvatarUrl(data.profile)"
      class="school-memberships-card-dialog__header__avatar" />
  </header>
  <main class="school-memberships-card-dialog__content">
    <strong class="school-memberships-card-dialog__content__headline">
      {{ data.profile.name }} aus Schulprofil entfernen
    </strong>
    <p class="school-memberships-card-dialog__content__copy">
      Bist du sicher, dass du diesen Mitarbeitenden aus dem Schulprofil der
      Schuleinheit löschen möchtest?
    </p>
    <p class="school-memberships-card-dialog__content__copy">
      <strong>Bitte beachte:</strong> Wenn du den Mitarbeitenden aus der
      Schuleinheit entfernst, wird er auch nicht mehr in den Profilen der
      Schulstandorte angezeigt. Diese Aktion kann von dir nicht rückgängig
      gemacht werden. Der Mitarbeitende muss sich erneut hinzufügen.
    </p>
  </main>
  <footer class="school-memberships-card-dialog__meta">
    <button
      mat-dialog-close
      class="school-memberships-card-dialog__meta__button school-memberships-card-dialog__meta__button--abort">
      Abbrechen
    </button>
    <button
      class="school-memberships-card-dialog__meta__button school-memberships-card-dialog__meta__button--action"
      [mat-dialog-close]="true">
      Löschen
    </button>
  </footer>
</section>
