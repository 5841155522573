import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, from, Observable } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { v4 as uuid } from 'uuid';

import { JobService } from '../../../../services/job.service';
import { Job, JobContact } from '../../../../types/job';
import { FileService } from '../../../../services/files.service';
import { BucketFile } from '../../../../types/core';

export type Contact = Omit<
  JobContact,
  'jobFunction' | 'name' | 'phone' | 'email'
> & { form: FormGroup<ContactForm>; file?: ContactFile };

export type ContactForm = {
  name: FormControl<FormValue>;
  phone: FormControl<FormValue>;
  email: FormControl<FormValue>;
  jobFunction: FormControl<FormValue>;
};

export type ContactFile = { base64?: string; blob?: Blob };

type FormValue = string | null;

@Component({
  selector: 'app-job-contact-form',
  templateUrl: './job-contact-form.component.html',
  styleUrl: './job-contact-form.component.scss',
})
export class JobContactFormComponent implements OnInit {
  id: string;
  loading: boolean = false;
  job$: Observable<Job>;
  job?: Job;
  private data: Contact[] = [];
  private deletable: string[] = [];

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private jobService = inject(JobService);
  private fb = inject(FormBuilder);
  private fileService = inject(FileService);

  constructor() {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.job$ = this.jobService.getById(this.id);
  }

  ngOnInit(): void {
    from(this.jobService.getContacts(this.id)).subscribe(contacts => {
      this.data = contacts.map(this.toContact.bind(this));
    });
    this.job$.subscribe(job => (this.job = job));
  }

  get admins(): Contact[] {
    return this.data.filter(contact => contact.isAdmin);
  }

  get contacts(): Contact[] {
    return this.data.filter(contact => !contact.isAdmin);
  }

  remove(id: string): void {
    const index = this.data.findIndex(contact => contact.userId === id);
    const url = this.data[index].avatarUrl;
    if (url) this.deletable.push(url);
    this.data.splice(index, 1);
  }

  change({
    userId,
    ...rest
  }: { userId: Contact['userId'] } & Partial<Contact>): void {
    const index = this.data.findIndex(contact => contact.userId === userId);
    this.data[index] = { ...this.data[index], ...rest };
  }

  isValid(): boolean {
    return this.data.every(({ form }) => form.valid);
  }

  add(): void {
    this.data.push(this.toContact());
  }

  async save(): Promise<void> {
    try {
      this.loading = true;
      const contacts: JobContact[] = [];

      if (this.deletable.length) {
        for (const url of this.deletable) {
          await this.fileService.remove(url);
        }
      }

      for (const contact of this.data) {
        const bucketFile = await this.uploadAvatar({
          file: contact.file,
          uid: contact.userId,
        });

        contacts.push({
          userId: contact.userId,
          isAdmin: !!contact.isAdmin,
          visible: !!contact.visible,
          avatarUrl: bucketFile?.url ?? contact.avatarUrl,
          name: contact.form.value.name ?? '',
          phone: contact.form.value.phone ?? '',
          jobFunction: contact.form.value.jobFunction ?? '',
          email: contact.form.value.email ?? '',
        });
      }

      await firstValueFrom(this.jobService.update(this.id, { contacts }));

      await this.router.navigate(['jobs', this.id]);
    } catch (error) {
      console.error('Error while saving job contacts', error);
    } finally {
      this.loading = false;
    }
  }

  private async uploadAvatar({
    file,
    uid,
  }: {
    file?: ContactFile;
    uid: string;
  }): Promise<BucketFile | null> {
    return file?.blob
      ? this.fileService.uploadAsync(
          `/schools/${this.job?.schoolId}/jobs/${this.id}/contacts`,
          file.blob,
          uid,
          true
        )
      : null;
  }

  private toContact(contact?: JobContact): Contact {
    return {
      ...contact,
      userId: contact?.userId ?? uuid(),
      form: this.toFormGroup(contact),
    };
  }

  private toFormGroup(contact?: JobContact): FormGroup<ContactForm> {
    return this.fb.group({
      name: [contact?.name ?? '', [Validators.required]],
      phone: [contact?.phone ?? '', []],
      jobFunction: [contact?.jobFunction ?? '', [Validators.required]],
      email: [contact?.email ?? '', [Validators.required, Validators.email]],
    });
  }
}
