<button
  mat-icon-button
  [disabled]="disabled"
  [ngClass]="{ 'fab-button-color': background }"
  class="scale-[0.85] self-center">
  @if (icon.includes('custom_')) {
    <mat-icon
      [ngClass]="{ invert: color === 'light' }"
      class="scale-[1.7]"
      [svgIcon]="icon"></mat-icon>
  } @else {
    <mat-icon
      [ngClass]="{ white: color === 'light' }"
      class="material-symbols-rounded"
      >{{ icon }}</mat-icon
    >
  }
</button>
