import { Component, inject, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionType } from 'src/app/types/subscription';
import { OrderProcessOrderingComponent } from './order-process-ordering/order-process-ordering.component';

export type OrderProcessComponentProps = {
  page?: number;
  type: SubscriptionType;
};

@Component({
  selector: 'app-order-process',
  templateUrl: './order-process.component.html',
  styleUrls: ['./order-process.component.scss'],
})
export class OrderProcessComponent {
  @ViewChild('stepperContainer') public stepperContainer:
    | OrderProcessOrderingComponent
    | undefined;

  page = 0;
  selectedOffer: SubscriptionType | null = null;

  readonly dialogRef = inject(MatDialogRef<OrderProcessComponentProps>);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrderProcessComponentProps
  ) {
    if (this.data) {
      this.page = this.data.page ?? 0;
      this.selectedOffer = this.data.type ?? null;
    }
  }

  onNext() {
    this.page++;
  }

  onPrev() {
    // We are on the page with the stepper
    if (this.page === 1) {
      // Just go back one page when on the first step
      if (this.stepperContainer?.getCurrentStep() === 0) {
        this.page--;
      }
      // Go back one step in the stepper on every other steps
      else {
        this.stepperContainer?.onPrev();
      }
    } else {
      this.page--;
    }
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSelectOffer(type: SubscriptionType) {
    this.selectedOffer = type;
    this.onNext();
  }
}
