<strong class="font-bold text-2xl md:text-4xl"> Arbeitstage </strong>

<div
  class="rounded-2xl pt-5 pr-5 pb-3 pl-3 md:pt-10 md:pr-10 md:pb-7 md:pl-7 mt-5 text-sm md:text-base"
  [ngClass]="{
    'bg-job-fulltime-light text-job-fulltime-dark': job.isFullTimeJob,
    'bg-job-substitute-light text-job-substitute-dark': !job.isFullTimeJob
  }">
  <app-workload-matrix
    [isFullTimeJob]="job.isFullTimeJob"
    [formControl]="workdayMatrix"></app-workload-matrix>
</div>
