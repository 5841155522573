import { Component, computed, input } from '@angular/core';
import { School } from '../../../../../../types/school';
import { getPublicFileUrl } from '../../../../../../core/helpers';

@Component({
  selector: 'app-profile-header-memberships',
  templateUrl: './profile-header-memberships.component.html',
  styleUrl: './profile-header-memberships.component.scss',
})
export class ProfileHeaderMembershipsComponent {
  readonly pid = input<string>();
  readonly isEditable = input.required<boolean>();
  readonly schools = input.required<School[]>();
  readonly hasMemberships = computed(() => !!this.schools().length);

  getSchoolAvatarUrl(school: School) {
    if (school.avatar && school.id) {
      return getPublicFileUrl('schools', school.id, 'avatar');
    }
    return 'assets/defaults/school_avatar.svg';
  }
}
