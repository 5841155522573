import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import {
  Auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateEmail,
} from '@angular/fire/auth';
import { FormBuilder, Validators } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { SettingsEmailChangeConfirmDialogComponent } from '../settings-email-change-confirm-dialog/settings-email-change-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProfileService } from '../../../services/profile.service';
import { Router } from '@angular/router';
import { ProfileContact } from '../../../types/profile';
import {
  isFirebaseErrorKnown,
  mapFirebaseErrorCodeToUserMessage,
} from 'src/app/core/firebaseErrorMapper';
import { SettingsPasswordResetConfirmDialogComponent } from '../settings-password-reset-confirm-dialog/settings-password-reset-confirm-dialog.component';
import { FirebaseError } from '@angular/fire/app';

@Component({
  selector: 'app-settings-change-email',
  templateUrl: './settings-change-email.component.html',
  styleUrls: ['./settings-change-email.component.scss'],
})
export class SettingsChangeEmailComponent {
  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    currentPw: ['', [Validators.required]],
  });
  contact$: Observable<ProfileContact> = EMPTY;

  loading = false;
  generalErrorMessage =
    'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.';
  changeEmailError = '';

  constructor(
    private fb: FormBuilder,
    private auth: Auth,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private router: Router,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.set([
      { text: 'Deine Einstellungen', link: 'settings' },
      { text: 'Nutzerkonto', link: 'settings/account' },
      { text: 'Kontaktdaten', link: 'settings/change-contact' },
      { text: 'E-Mail Adresse ändern' },
    ]);

    this.contact$ = profileService.getContact();
  }

  async resetPassword() {
    const user = this.auth.currentUser;

    if (!user?.email) {
      // TODO: Error Handling
      return;
    }

    this.dialog.open(SettingsPasswordResetConfirmDialogComponent, {
      data: user.email,
    });

    await sendPasswordResetEmail(this.auth, user.email!);
  }

  async change() {
    this.loading = true;
    const user = this.auth.currentUser;
    const newEmail = this.form.get('email')?.value;
    const currentPw = this.form.get('currentPw')?.value;

    // Show general error message when data consistency is not given
    if (!user?.email || !newEmail || !currentPw) {
      this.changeEmailError = this.generalErrorMessage;
      this.loading = false;

      return;
    }

    try {
      // Do a reauthentication before (https://stackoverflow.com/a/37812541)
      const userCredentials = EmailAuthProvider.credential(
        user.email!,
        currentPw
      );

      await reauthenticateWithCredential(user, userCredentials);
      await updateEmail(user, newEmail);
      await sendEmailVerification(user);
    } catch (error) {
      // Known errors
      if (isFirebaseErrorKnown(error)) {
        this.changeEmailError = mapFirebaseErrorCodeToUserMessage(
          (error as FirebaseError).code
        );
      }

      // Unkown errors
      if (!this.changeEmailError) {
        this.changeEmailError = this.generalErrorMessage;
      }

      this.loading = false;
      return;
    }

    this.dialog.open(SettingsEmailChangeConfirmDialogComponent, {
      minWidth: '400px',
      minHeight: '400px',
      data: newEmail,
    });

    this.loading = false;
    this.profileService.updateContact({ email: newEmail });

    this.router.navigate(['/settings/change-contact']);
  }
}
