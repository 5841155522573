@if (!showEmptyCard) {
  <div class="flex flex-col gap-4">
    <div>
      <strong class="font-bold text-2xl md:text-4xl">Über mich</strong>
      @if (isEditable) {
        <app-edit-button class="pl-4" routerLink="edit/about"></app-edit-button>
      }
    </div>
    <app-rich-text-view
      [html]="profile.about_html"
      tailwindCssLineClamp="line-clamp-6"></app-rich-text-view>
  </div>
}
@if (showEmptyCard && isEditable) {
  <app-empty-card
    title="Über mich"
    subtitle="Was macht dich aus?"
    description="Stell dich hier kurz vor – sag wer du bist und heb hervor, was dich besonders macht."
    link="edit/about"></app-empty-card>
}
