import { Component, Input, OnInit } from '@angular/core';
import { ChipSkin, getChipSkinColors } from '../chip/chip.component';

@Component({
  selector: 'app-job-badge',
  templateUrl: './job-badge.component.html',
  styleUrl: './job-badge.component.scss',
})
export class JobBadgeComponent implements OnInit {
  @Input({ required: true }) type: 'fulltime' | 'substitute' | 'hospitation' =
    'fulltime';

  colors: { bg: string; text: string } = { bg: '', text: '' };
  icon: 'work' | 'work_history' | 'visibility' = 'work';

  ngOnInit(): void {
    let badgeSkin: ChipSkin;

    switch (this.type) {
      case 'substitute':
        badgeSkin = 'job_substitute';
        break;
      case 'hospitation':
        badgeSkin = 'hospitation';
        break;
      default:
        badgeSkin = 'job_fulltime';
        break;
    }

    this.colors = getChipSkinColors(badgeSkin);

    switch (this.type) {
      case 'substitute':
        this.icon = 'work_history';
        break;
      case 'hospitation':
        this.icon = 'visibility';
        break;
      default:
        this.icon = 'work';
        break;
    }
  }
}
