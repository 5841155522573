import { Component, inject, input } from '@angular/core';
import { PermissionsService } from '../../../services/permissions.service';
import { Auth } from '@angular/fire/auth';
import { isBefore } from 'date-fns';

@Component({
  selector: 'app-membership-banner-card',
  templateUrl: './membership-banner-card.component.html',
  styleUrl: './membership-banner-card.component.scss',
})
export class MembershipBannerCardComponent {
  readonly pid = input.required<string>();
  private readonly dueDate = new Date('2026-04-30');
  isSchoolAdmin: boolean = false;
  isVisible: boolean = false;

  private readonly permissionService = inject(PermissionsService);
  private readonly auth = inject(Auth);

  constructor() {
    if (this.auth.currentUser?.uid) {
      this.permissionService
        .loadPermissions(this.auth.currentUser?.uid)
        .subscribe(permissions => {
          this.isSchoolAdmin = (permissions ?? []).length > 0;
        });
    }

    this.isVisible = isBefore(new Date(), this.dueDate);
  }
}
