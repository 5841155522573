import { Component, Input, OnInit } from '@angular/core';
import { School } from '../../../../types/school';
import { isRichTextFieldEmpty } from 'src/app/core/richText';
import { getChipSkinFromSchoolType } from 'src/app/core/helpers';
import { ChipSkin } from 'src/app/components/chip/chip.component';

@Component({
  selector: 'app-school-weoffer-card',
  templateUrl: './school-weoffer-card.component.html',
  styleUrls: ['./school-weoffer-card.component.scss'],
})
export class SchoolWeofferCardComponent implements OnInit {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isEditable!: boolean;

  showEmptyCard = false;
  chipSkin: ChipSkin = 'general';

  ngOnInit(): void {
    this.showEmptyCard =
      isRichTextFieldEmpty(this.school.whatWeOffer_html) &&
      (!this.school.whatWeOfferHighlights ||
        this.school.whatWeOfferHighlights.length === 0);
    this.chipSkin = getChipSkinFromSchoolType(this.school.type);
  }
}
