import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  firstValueFrom,
  from,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import type { School, SchoolLocation } from '../../../../types/school';
import { FormControl } from '@angular/forms';
import { getPublicFileUrl } from '../../../../core/helpers';
import { Hit, SearchResponse } from '@algolia/client-search';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MembershipsService } from '../../../../services/memberships.service';
import { SchoolService } from '../../../../services/school.service';

@Component({
  selector: 'app-profile-membership-form',
  templateUrl: './profile-membership-form.component.html',
  styleUrl: './profile-membership-form.component.scss',
})
export class ProfileMembershipFormComponent implements OnInit {
  id?: string = undefined;

  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly membershipsService = inject(MembershipsService);
  private readonly schoolService = inject(SchoolService);

  loading: boolean = false;
  initial: School[] = [];
  memberships: School[] = [];
  availableSchools$: Observable<School[]> = EMPTY;
  query = new FormControl<string | undefined>(undefined, []);

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') ?? undefined;

    this.membershipsService.getMembershipSchools(this.id).subscribe(schools => {
      if (!this.loading) {
        this.memberships = [...schools];
      }
      this.initial = [...schools];
    });

    this.availableSchools$ = this.query.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      switchMap(this._search.bind(this)),
      map(response => this._filter(response.hits)),
      switchMap(sids => this.schoolService.getSchoolsByIds(sids))
    );
  }

  private _filter(hits: Hit<School>[]): string[] {
    return hits
      .filter(
        hit => !this.memberships.find(school => school.id === hit.objectID)
      )
      .map(({ objectID }) => objectID);
  }

  private _search(query?: string | null): Observable<SearchResponse<School>> {
    return from(
      this.schoolService.search(query ?? '', {
        facetFilters: [['isPublic:true'], ['isRoot:true']],
        hitsPerPage: 30,
      })
    );
  }

  private _gap(memberships: School[]): School[] {
    return this.initial.filter(
      school => !memberships.some(s => s.id === school.id)
    );
  }

  add({ option: { value: school } }: MatAutocompleteSelectedEvent): void {
    this.query.setValue(undefined);
    this.memberships.push(school);
  }

  remove(school: School): void {
    this.memberships = this.memberships.filter(
      s => s.id !== school.id && s.rootId !== school.id
    );
  }

  async toggleLocation(location: SchoolLocation): Promise<void> {
    const i = this.memberships.findIndex(s => s.id === location.id);

    if (i === -1 && location.id) {
      this.loading = true;

      const school = await firstValueFrom(
        this.schoolService.getById(location.id)
      );
      const rootIndex = this.memberships.findIndex(s =>
        s.locations?.some(loc => loc.id === location.id)
      );

      if (rootIndex === -1) {
        this.memberships.push(school);
      } else {
        this.memberships.splice(rootIndex + 1, 0, school);
      }

      this.loading = false;
    } else {
      this.memberships.splice(i, 1);
    }
  }

  async save(): Promise<void> {
    try {
      this.loading = true;
      await this.membershipsService.setBelongsTo({
        schoolIds: this.memberships.map(({ id }) => id!),
        droppableSchoolIds: this._gap(this.memberships).map(({ id }) => id!),
        userId: this.id,
        sendRemovedByHimselfNotification: true,
        sendAddedByHimselfNotification: true,
      });
      await this.router.navigate(['/me', this.id]);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  get filteredSchools(): School[] {
    return this.memberships.filter(school => school.isRoot);
  }

  isChecked(id?: string): boolean {
    return this.memberships.some(school => school.id === id);
  }

  getSchoolAvatarUrl(school: School) {
    if (school.avatar && school.id) {
      return getPublicFileUrl('schools', school.id, 'avatar');
    }
    return 'assets/defaults/school_avatar.svg';
  }
}
