import { Component, Input, input, OnInit } from '@angular/core';
import { getPublicFileUrl } from 'src/app/core/helpers';

@Component({
  selector: 'app-job-default-image',
  templateUrl: './job-default-image.component.html',
  styleUrl: './job-default-image.component.scss',
})
export class JobDefaultImageComponent implements OnInit {
  @Input({ required: true }) schoolId = '';

  schoolHeaderImageUrl = '';

  ngOnInit(): void {
    this.schoolHeaderImageUrl = getPublicFileUrl(
      'schools',
      this.schoolId,
      'backgroundImage'
    );
  }
}
