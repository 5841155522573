import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { SearchIndex } from 'algoliasearch';
import { searchClient } from '../core/algolia';
import {
  Profile,
  ProfileAttachements,
  ProfileContact,
  ProfileJobFunction,
  ProfilePreferences,
} from '../types/profile';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import { Auth, authState } from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Analytics, logEvent, setUserId } from '@angular/fire/analytics';
import { SchoolLevels, SchoolLevelsShortMap } from '../types/school';

type FacetFilters =
  | string
  | readonly string[]
  | readonly (string | readonly string[])[]
  | undefined;
type AroundRadius = number | 'all' | undefined;
type AroundLatLng = string | undefined;

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private currentProfile = new BehaviorSubject<Profile | null>(null);

  private readonly currentUserKey = 'currentUser';
  private index: SearchIndex;
  private readonly deleteProfileFunc: () => Observable<void>;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private router: Router,
    private analytics: Analytics,
    functions: Functions
  ) {
    this.index = searchClient.initIndex('profiles');
    this.deleteProfileFunc = httpsCallableData(functions, 'deleteprofile', {});
  }

  search(
    query: string,
    options: {
      facetFilters?: FacetFilters;
      aroundRadius?: AroundRadius;
      aroundLatLng?: AroundLatLng;
      page?: number;
      hitsPerPage?: number;
      filters?: string;
      maxFacetHits?: number;
    }
  ) {
    return this.index.search<Profile>(query, { ...options });
  }

  delete() {
    logEvent(this.analytics, 'profile_delete');
    return this.deleteProfileFunc();
  }

  getById(id: string) {
    const me = this.getFromLocalStorage();
    if (me?.id === id) {
      return this.getMe(false);
    }
    const docRef = doc(this.firestore, `profiles/${id}`);
    return from(getDoc(docRef)).pipe(map(p => p.data() as Profile));
  }

  getMe(cache = false) {
    if (cache) {
      const profile = this.getFromLocalStorage();
      if (profile) {
        return of(profile);
      }
    }

    return authState(this.auth).pipe(
      filter(user => !!user),
      mergeMap(user => {
        const id = user?.uid;
        const docRef = doc(this.firestore, `profiles/${id}`);
        return from(getDoc(docRef)).pipe(
          tap(contact => {
            if (!contact.exists()) {
              const newsletter =
                localStorage.getItem('OPTIN_NEWSLETTER') === '1' ? true : false;
              localStorage.removeItem('OPTIN_NEWSLETTER');
              return this.createProfile(id!, user!.email!, newsletter);
            }

            return this.getContact().subscribe(c => {
              if (c.email !== user?.email && user?.email) {
                return this.updateContact({
                  email: user.email,
                  phone: c.phone,
                });
              }
              return EMPTY;
            });
          })
        );
      }),
      map(d => d.data() as Profile),
      tap(profile =>
        localStorage.setItem(this.currentUserKey, JSON.stringify(profile))
      )
    );
  }

  isAuthorized() {
    return authState(this.auth).pipe(
      traceUntilFirst('auth'),
      map(u => !!u)
    );
  }

  authorize() {
    return this.getMe().pipe(
      tap(profile => setUserId(this.analytics, profile.id!)),
      map(_ => undefined)
    );
  }

  getContact(id?: string) {
    const uid = id ? id : this.auth.currentUser?.uid;
    const docRef = doc(this.firestore, `profiles/${uid}/settings/contact`);
    return from(getDoc(docRef)).pipe(map(p => p.data() as ProfileContact));
  }

  updateContact(contact: Partial<ProfileContact>) {
    const id = this.auth.currentUser?.uid;
    const docRef = doc(this.firestore, `profiles/${id}/settings/contact`);
    return from(updateDoc(docRef, contact));
  }

  getPreferences() {
    const id = this.auth.currentUser?.uid;
    const docRef = doc(this.firestore, `profiles/${id}/settings/preferences`);
    return from(getDoc(docRef)).pipe(map(p => p.data() as ProfilePreferences));
  }

  deleteNotificationSubscription(id: string) {
    const docRef = doc(this.firestore, `notificationSubscriptions/${id}`);
    return from(deleteDoc(docRef));
  }

  updatePreferences(preferences: Partial<ProfilePreferences>) {
    const id = this.auth.currentUser?.uid;
    const docRef = doc(this.firestore, `profiles/${id}/settings/preferences`);
    return from(updateDoc(docRef, preferences));
  }

  getAttachements() {
    const id = this.auth.currentUser?.uid;
    const docRef = doc(this.firestore, `profiles/${id}/settings/attachements`);
    return from(getDoc(docRef)).pipe(map(p => p.data() as ProfileAttachements));
  }

  updateAttachements(attachements: ProfileAttachements) {
    const id = this.auth.currentUser?.uid;
    const docRef = doc(this.firestore, `profiles/${id}/settings/attachements`);
    return from(setDoc(docRef, attachements));
  }

  update(profile: Partial<Profile>) {
    const id = this.auth.currentUser?.uid;
    this.updateCache(profile);
    const docRef = doc(this.firestore, `profiles/${id}`);
    return from(updateDoc(docRef, profile));
  }

  getLatest(count = 3) {
    const colRef = query(
      collection(this.firestore, 'profiles'),
      where('isPublic', '==', true),
      orderBy('createdOn', 'desc'),
      limit(count)
    );
    return collectionData(colRef, { idField: 'id' }) as Observable<Profile[]>;
  }

  getJobLevels(levels: (typeof SchoolLevels)[number][]) {
    if (
      levels.includes('Kindergarten') &&
      levels.includes('Unterstufe') &&
      levels.includes('Mittelstufe')
    ) {
      const l = levels.filter(
        l => l !== 'Kindergarten' && l !== 'Unterstufe' && l !== 'Mittelstufe'
      );
      const rest = l.length > 0 ? ` - ${l.join(' - ')}` : '';
      return `Kiga - Primar${rest}`;
    }
    if (levels.includes('Unterstufe') && levels.includes('Mittelstufe')) {
      const l = levels.filter(l => l !== 'Unterstufe' && l !== 'Mittelstufe');
      const rest = l.length > 0 ? ` - ${l.join(' - ')}` : '';
      return `Primar${rest}`;
    }
    if (levels.includes('Kindergarten') && levels.includes('Unterstufe')) {
      const l = levels.filter(l => l !== 'Kindergarten' && l !== 'Unterstufe');
      const rest = l.length > 0 ? ` - ${l.join(' - ')}` : '';
      return `KUST${rest}`;
    }

    return levels
      .map(level => SchoolLevelsShortMap[level] || level)
      .join(' - ');
  }

  getJobTitles(jobFunctions: ProfileJobFunction[]) {
    return jobFunctions.map(jf => this.getJobTitle(jf)).join(' | ');
  }

  getJobTitle(jobFunction: ProfileJobFunction) {
    const inStudy = jobFunction.inStudy ? ' i.A.' : '';
    if (this.getJobLevels(jobFunction.jobLevels)?.length > 0) {
      return `${jobFunction.name}${inStudy} - ${this.getJobLevels(jobFunction.jobLevels) || []}`;
    }

    return `${jobFunction.name}${inStudy}`;
  }

  private updateCache(newData: Partial<Profile>) {
    const current = this.getFromLocalStorage();
    const profile: Partial<Profile> = { ...current, ...newData };
    this.currentProfile.next(profile as Profile);
    localStorage.setItem(this.currentUserKey, JSON.stringify(profile));
  }

  private getFromLocalStorage() {
    let data;
    try {
      data = localStorage.getItem(this.currentUserKey);
      return data ? (JSON.parse(data) as Profile) : null;
    } catch (e) {
      console.warn('could not retrieve profile from cache', { data, e });
      return null;
    }
  }

  private createProfile(id: string, email: string, newsletter: boolean) {
    const profile = {
      id,
      createdOn: new Date().toISOString(),
      isPublic: false,
      isComplete: false,
    };
    return forkJoin([
      from(setDoc(doc(this.firestore, `profiles/${id}`), profile)),
      from(
        setDoc(doc(this.firestore, `profiles/${id}/settings/contact`), {
          email,
        })
      ),
      from(
        setDoc(doc(this.firestore, `profiles/${id}/settings/preferences`), {
          newsletterEmailOptIn: newsletter || false,
          analyticTrackingEnabled: true,
          chatNotificationEmailOptIn: true,
        })
      ),
    ]).pipe(
      tap(() => {
        this.updateCache(profile);
        return from(
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['login']))
        );
      })
    );
  }

  public getProfiles(ids: string[] = []) {
    return this.index.getObjects<Profile>(ids);
  }
}
