<section class="profile-membership-form">
  <div class="profile-membership-form__container">
    <header class="profile-membership-form__container__header">
      <h1 class="profile-membership-form__container__header__headline">
        Schule hinzufügen
      </h1>
      <p class="profile-membership-form__container__header__copy">
        Wenn dein Schulhaus bei Bildigsnetz registriert ist, wirst du auf dem
        Profil deiner Schule angezeigt.
      </p>
    </header>

    <div class="profile-membership-form__container__form">
      <mat-form-field
        appearance="fill"
        class="profile-membership-form__container__form__search">
        <mat-label>Name Schuleinheit/-standort</mat-label>
        <input
          type="text"
          matInput
          [formControl]="query"
          [matAutocomplete]="auto" />
        <mat-autocomplete
          (optionSelected)="add($event)"
          #auto="matAutocomplete"
          autoActiveFirstOption>
          @for (school of availableSchools$ | async; track school.id) {
            <mat-option [value]="school">
              {{ school.name }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <aside class="profile-membership-form__container__form__meta">
        <a
          mat-button
          color="primary"
          [disabled]="loading"
          [routerLink]="['/me', id]">
          Abbrechen
        </a>
        <app-action-button
          [loading]="loading"
          [disabled]="loading"
          (onClick)="save()"
          text="Speichern" />
      </aside>
    </div>

    @if (filteredSchools.length) {
      <ul class="profile-membership-form__container__result">
        @for (school of filteredSchools; track school.id) {
          <li class="profile-membership-form__container__result__item">
            <div class="profile-membership-form__container__result__item__unit">
              <figure
                class="profile-membership-form__container__result__item__unit__avatar">
                <img [alt]="school.name" [src]="getSchoolAvatarUrl(school)" />
              </figure>
              <strong
                class="profile-membership-form__container__result__item__unit__name">
                {{ school.name }}
              </strong>
              <app-edit-button
                icon="delete"
                [disabled]="loading"
                (click)="remove(school)" />
            </div>
            @if (school.locations?.length) {
              <div
                class="profile-membership-form__container__result__item__locations">
                <p
                  class="profile-membership-form__container__result__item__locations__copy">
                  Bei welchem Standort bist du tätig?
                </p>
                <ul
                  class="profile-membership-form__container__result__item__locations__list">
                  @for (
                    location of school.locations ?? [] | sortby: 'order';
                    track location.id
                  ) {
                    @if (location.isPublic) {
                      <li
                        class="profile-membership-form__container__result__item__locations__list__location">
                        <mat-checkbox
                          [checked]="isChecked(location.id)"
                          [disabled]="loading"
                          (change)="toggleLocation(location)">
                          {{ location.name }}
                        </mat-checkbox>
                      </li>
                    }
                  }
                </ul>
              </div>
            }
          </li>
        }
      </ul>
    }
  </div>
</section>
