import { Component, Input, OnInit } from '@angular/core';
import {
  getCityWithCantonAbbrevation,
  getPublicFileUrl,
} from '../../../../core/helpers';
import { Profile, ProfileJobFunction } from '../../../../types/profile';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { ProfileService } from '../../../../services/profile.service';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { StreamChannelService } from '../../../../../modules/chat/services/stream-channel.service';
import { School } from '../../../../types/school';
import { MembershipsService } from '../../../../services/memberships.service';
import { isBefore } from 'date-fns';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent implements OnInit {
  @Input({ required: true }) profile!: Profile;
  @Input({ required: true }) isEditable!: boolean;

  memberships: School[] = [];
  bannerVisible: boolean = false;
  private readonly bannerDueDate = new Date('2026-04-30');

  constructor(
    private analytics: Analytics,
    private profileService: ProfileService,
    private streamChannelService: StreamChannelService,
    private router: Router,
    private membershipsService: MembershipsService
  ) {}

  ngOnInit(): void {
    this.membershipsService
      .getMembershipSchools(this.profile?.id)
      .subscribe(schools => {
        this.memberships = schools;
        this.bannerVisible =
          this.isEditable &&
          !schools.length &&
          isBefore(new Date(), this.bannerDueDate);
      });
  }

  getAvatarUrl(profile: Profile) {
    if (profile.avatar && profile.id) {
      return getPublicFileUrl('profiles', profile.id, 'avatar');
    }

    return 'assets/defaults/avatar.svg';
  }

  getBackgroundUrl(profile: Profile) {
    if (profile.backgroundImage && profile.id) {
      return getPublicFileUrl('profiles', profile.id, 'backgroundImage');
    }

    return 'assets/defaults/background.jpeg';
  }

  getLocation(profile: Profile) {
    return getCityWithCantonAbbrevation(profile);
  }

  getJobTitles(jobFunctions: ProfileJobFunction[]) {
    return this.profileService.getJobTitles(jobFunctions);
  }

  showContactButton() {
    return !this.isEditable;
  }

  track() {
    logEvent(this.analytics, 'contact_user');
  }

  async openChat(): Promise<void> {
    if (this.profile.id) {
      from(this.streamChannelService.create([this.profile.id])).subscribe(
        async ({ id }) => {
          logEvent(this.analytics, 'open_chat');
          await this.router.navigate(['/chat/overview', id]);
        }
      );
    }
  }
}
